@import './mixins';
@import './variables';

// Display
.xs-d-none {
    @include breakpoint($xs) {
        display: none;
    }
}
.sm-d-none {
    @include breakpoint($sm) {
        display: none;
    }
}
.md-d-none {
    @include breakpoint($md) {
        display: none;
    }
}
.lg-d-none {
    @include breakpoint($lg) {
        display: none;
    }
}
.xl-d-none {
    @include breakpoint($xl) {
        display: none;
    }
}

.xs-d-initial {
    @include breakpoint($xs) {
        display: initial;
    }
}
.sm-d-initial {
    @include breakpoint($sm) {
        display: initial;
    }
}
.md-d-initial {
    @include breakpoint($md) {
        display: initial;
    }
}
.lg-d-initial {
    @include breakpoint($lg) {
        display: initial;
    }
}

// Row & Col

.xs-row {
    @include breakpoint($xs) {
        display: flex;
        flex-direction: row;
    }
}
.xs-col {
    @include breakpoint($xs) {
        display: flex;
        flex-direction: column;
    }
}

.sm-row {
    @include breakpoint($sm) {
        display: flex;
        flex-direction: row;
    }
}
.sm-col {
    @include breakpoint($sm) {
        display: flex;
        flex-direction: column;
    }
}

.md-row {
    @include breakpoint($md) {
        display: flex;
        flex-direction: row;
    }
}
.md-col {
    @include breakpoint($md) {
        display: flex;
        flex-direction: column;
    }
}

.lg-row {
    @include breakpoint($lg) {
        display: flex;
        flex-direction: row;
    }
}
.lg-col {
    @include breakpoint($lg) {
        display: flex;
        flex-direction: column;
    }
}

.xl-row {
    @include breakpoint($xl) {
        display: flex;
        flex-direction: row;
    }
}

.xl-col {
    @include breakpoint($xl) {
        display: flex;
        flex-direction: column;
    }
}

// Align & Justify
.xs-align-center {
    @include breakpoint($xs) {
        align-items: center;
    }
}
.xs-align-start {
    @include breakpoint($xs) {
        align-items: start;
    }
}
.xs-align-end {
    @include breakpoint($xs) {
        align-items: end;
    }
}
.sm-align-center {
    @include breakpoint($sm) {
        align-items: center;
    }
}
.sm-align-start {
    @include breakpoint($sm) {
        align-items: start;
    }
}
.sm-align-end {
    @include breakpoint($sm) {
        align-items: end;
    }
}
.md-align-center {
    @include breakpoint($md) {
        align-items: center;
    }
}
.md-align-start {
    @include breakpoint($md) {
        align-items: start;
    }
}
.md-align-end {
    @include breakpoint($md) {
        align-items: end;
    }
}
.lg-align-center {
    @include breakpoint($lg) {
        align-items: center;
    }
}
.lg-align-start {
    @include breakpoint($lg) {
        align-items: start;
    }
}
.lg-align-end {
    @include breakpoint($lg) {
        align-items: end;
    }
}
.xl-align-center {
    @include breakpoint($xl) {
        align-items: center;
    }
}
.xl-align-start {
    @include breakpoint($xl) {
        align-items: start;
    }
}


.xs-justify-end {
    @include breakpoint($xs) {
        justify-content: end;
    }
}
.xs-justify-center {
    @include breakpoint($xs) {
        justify-content: center;
    }
}
.xs-justify-start {
    @include breakpoint($xl) {
        justify-content: start;
    }
}
.sm-justify-end {
    @include breakpoint($sm) {
        justify-content: end;
    }
}
.sm-justify-center {
    @include breakpoint($sm) {
        justify-content: center;
    }
}
.sm-justify-start {
    @include breakpoint($sm) {
        justify-content: start;
    }
}
.md-justify-end {
    @include breakpoint($md) {
        justify-content: end;
    }
}
.md-justify-center {
    @include breakpoint($md) {
        justify-content: center;
    }
}
.md-justify-start {
    @include breakpoint($md) {
        justify-content: start;
    }
}
.lg-justify-end {
    @include breakpoint($lg) {
        justify-content: end;
    }
}
.lg-justify-center {
    @include breakpoint($lg) {
        justify-content: center;
    }
}
.lg-justify-start {
    @include breakpoint($lg) {
        justify-content: start;
    }
}
.xl-justify-end {
    @include breakpoint($xl) {
        justify-content: end;
    }
}
.xl-justify-center {
    @include breakpoint($xl) {
        justify-content: center;
    }
}
.xl-justify-start {
    @include breakpoint($xl) {
        justify-content: start;
    }
}


