$footer-background: #302e2e;
$font-color-light: #fff;
$font-color-dark: #302e2e;
$light-blue: #3aadfb;

.light-blue {
  background: $light-blue;
}

.color-white {
  color: $font-color-light;
}
.color-black {
  color: $font-color-dark;
}
.color-link-black {
  a {
    color: $font-color-dark !important;
  }
}
.color-link-black-underline {
  p {
    color: $font-color-dark !important;
    text-decoration: underline !important;
  }
}
.color-link-white {
  a {
    color: $font-color-light !important;
  }
}
.color-light-blue {
  color: $light-blue;
}

.light-gray {
  background-color: #ebebeb6b;
}
