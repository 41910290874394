@import './variables.scss';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('./icomoon.eot');
  src:  url('./icomoon.eot') format('embedded-opentype'),
    url('./icomoon.ttf') format('truetype'),
    url('./icomoon.woff') format('woff'),
    url('./icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-error {
  &:before {
    content: $icon-error; 
  }
}
.icon-error_outline {
  &:before {
    content: $icon-error_outline; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-add_alert {
  &:before {
    content: $icon-add_alert; 
  }
}
.icon-notification_important {
  &:before {
    content: $icon-notification_important; 
  }
}
.icon-album {
  &:before {
    content: $icon-album; 
  }
}
.icon-av_timer {
  &:before {
    content: $icon-av_timer; 
  }
}
.icon-closed_caption {
  &:before {
    content: $icon-closed_caption; 
  }
}
.icon-equalizer {
  &:before {
    content: $icon-equalizer; 
  }
}
.icon-explicit {
  &:before {
    content: $icon-explicit; 
  }
}
.icon-fast_forward {
  &:before {
    content: $icon-fast_forward; 
  }
}
.icon-fast_rewind {
  &:before {
    content: $icon-fast_rewind; 
  }
}
.icon-games {
  &:before {
    content: $icon-games; 
  }
}
.icon-hearing {
  &:before {
    content: $icon-hearing; 
  }
}
.icon-high_quality {
  &:before {
    content: $icon-high_quality; 
  }
}
.icon-loop {
  &:before {
    content: $icon-loop; 
  }
}
.icon-mic {
  &:before {
    content: $icon-mic; 
  }
}
.icon-mic_none {
  &:before {
    content: $icon-mic_none; 
  }
}
.icon-mic_off {
  &:before {
    content: $icon-mic_off; 
  }
}
.icon-movie {
  &:before {
    content: $icon-movie; 
  }
}
.icon-library_add {
  &:before {
    content: $icon-library_add; 
  }
}
.icon-library_books {
  &:before {
    content: $icon-library_books; 
  }
}
.icon-library_music {
  &:before {
    content: $icon-library_music; 
  }
}
.icon-new_releases {
  &:before {
    content: $icon-new_releases; 
  }
}
.icon-not_interested {
  &:before {
    content: $icon-not_interested; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-pause_circle_filled {
  &:before {
    content: $icon-pause_circle_filled; 
  }
}
.icon-pause_circle_outline {
  &:before {
    content: $icon-pause_circle_outline; 
  }
}
.icon-play_arrow {
  &:before {
    content: $icon-play_arrow; 
  }
}
.icon-play_circle_filled {
  &:before {
    content: $icon-play_circle_filled; 
  }
}
.icon-play_circle_outline {
  &:before {
    content: $icon-play_circle_outline; 
  }
}
.icon-playlist_add {
  &:before {
    content: $icon-playlist_add; 
  }
}
.icon-queue_music {
  &:before {
    content: $icon-queue_music; 
  }
}
.icon-radio {
  &:before {
    content: $icon-radio; 
  }
}
.icon-recent_actors {
  &:before {
    content: $icon-recent_actors; 
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat; 
  }
}
.icon-repeat_one {
  &:before {
    content: $icon-repeat_one; 
  }
}
.icon-replay {
  &:before {
    content: $icon-replay; 
  }
}
.icon-shuffle {
  &:before {
    content: $icon-shuffle; 
  }
}
.icon-skip_next {
  &:before {
    content: $icon-skip_next; 
  }
}
.icon-skip_previous {
  &:before {
    content: $icon-skip_previous; 
  }
}
.icon-snooze {
  &:before {
    content: $icon-snooze; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-subtitles {
  &:before {
    content: $icon-subtitles; 
  }
}
.icon-surround_sound {
  &:before {
    content: $icon-surround_sound; 
  }
}
.icon-video_library {
  &:before {
    content: $icon-video_library; 
  }
}
.icon-videocam {
  &:before {
    content: $icon-videocam; 
  }
}
.icon-videocam_off {
  &:before {
    content: $icon-videocam_off; 
  }
}
.icon-volume_down {
  &:before {
    content: $icon-volume_down; 
  }
}
.icon-volume_mute {
  &:before {
    content: $icon-volume_mute; 
  }
}
.icon-volume_off {
  &:before {
    content: $icon-volume_off; 
  }
}
.icon-volume_up {
  &:before {
    content: $icon-volume_up; 
  }
}
.icon-web {
  &:before {
    content: $icon-web; 
  }
}
.icon-hd {
  &:before {
    content: $icon-hd; 
  }
}
.icon-sort_by_alpha {
  &:before {
    content: $icon-sort_by_alpha; 
  }
}
.icon-airplay {
  &:before {
    content: $icon-airplay; 
  }
}
.icon-forward_10 {
  &:before {
    content: $icon-forward_10; 
  }
}
.icon-forward_30 {
  &:before {
    content: $icon-forward_30; 
  }
}
.icon-forward_5 {
  &:before {
    content: $icon-forward_5; 
  }
}
.icon-replay_10 {
  &:before {
    content: $icon-replay_10; 
  }
}
.icon-replay_30 {
  &:before {
    content: $icon-replay_30; 
  }
}
.icon-replay_5 {
  &:before {
    content: $icon-replay_5; 
  }
}
.icon-add_to_queue {
  &:before {
    content: $icon-add_to_queue; 
  }
}
.icon-fiber_dvr {
  &:before {
    content: $icon-fiber_dvr; 
  }
}
.icon-fiber_new {
  &:before {
    content: $icon-fiber_new; 
  }
}
.icon-playlist_play {
  &:before {
    content: $icon-playlist_play; 
  }
}
.icon-art_track {
  &:before {
    content: $icon-art_track; 
  }
}
.icon-fiber_manual_record {
  &:before {
    content: $icon-fiber_manual_record; 
  }
}
.icon-fiber_smart_record {
  &:before {
    content: $icon-fiber_smart_record; 
  }
}
.icon-music_video {
  &:before {
    content: $icon-music_video; 
  }
}
.icon-subscriptions {
  &:before {
    content: $icon-subscriptions; 
  }
}
.icon-playlist_add_check {
  &:before {
    content: $icon-playlist_add_check; 
  }
}
.icon-queue_play_next {
  &:before {
    content: $icon-queue_play_next; 
  }
}
.icon-remove_from_queue {
  &:before {
    content: $icon-remove_from_queue; 
  }
}
.icon-slow_motion_video {
  &:before {
    content: $icon-slow_motion_video; 
  }
}
.icon-web_asset {
  &:before {
    content: $icon-web_asset; 
  }
}
.icon-fiber_pin {
  &:before {
    content: $icon-fiber_pin; 
  }
}
.icon-branding_watermark {
  &:before {
    content: $icon-branding_watermark; 
  }
}
.icon-call_to_action {
  &:before {
    content: $icon-call_to_action; 
  }
}
.icon-featured_play_list {
  &:before {
    content: $icon-featured_play_list; 
  }
}
.icon-featured_video {
  &:before {
    content: $icon-featured_video; 
  }
}
.icon-note {
  &:before {
    content: $icon-note; 
  }
}
.icon-video_call {
  &:before {
    content: $icon-video_call; 
  }
}
.icon-video_label {
  &:before {
    content: $icon-video_label; 
  }
}
.icon-4k {
  &:before {
    content: $icon-4k; 
  }
}
.icon-missed_video_call {
  &:before {
    content: $icon-missed_video_call; 
  }
}
.icon-control_camera {
  &:before {
    content: $icon-control_camera; 
  }
}
.icon-business {
  &:before {
    content: $icon-business; 
  }
}
.icon-call {
  &:before {
    content: $icon-call; 
  }
}
.icon-call_end {
  &:before {
    content: $icon-call_end; 
  }
}
.icon-call_made {
  &:before {
    content: $icon-call_made; 
  }
}
.icon-call_merge {
  &:before {
    content: $icon-call_merge; 
  }
}
.icon-call_missed {
  &:before {
    content: $icon-call_missed; 
  }
}
.icon-call_received {
  &:before {
    content: $icon-call_received; 
  }
}
.icon-call_split {
  &:before {
    content: $icon-call_split; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-clear_all {
  &:before {
    content: $icon-clear_all; 
  }
}
.icon-comment {
  &:before {
    content: $icon-comment; 
  }
}
.icon-contacts {
  &:before {
    content: $icon-contacts; 
  }
}
.icon-dialer_sip {
  &:before {
    content: $icon-dialer_sip; 
  }
}
.icon-dialpad {
  &:before {
    content: $icon-dialpad; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-forum {
  &:before {
    content: $icon-forum; 
  }
}
.icon-import_export {
  &:before {
    content: $icon-import_export; 
  }
}
.icon-invert_colors_off {
  &:before {
    content: $icon-invert_colors_off; 
  }
}
.icon-live_help {
  &:before {
    content: $icon-live_help; 
  }
}
.icon-location_off {
  &:before {
    content: $icon-location_off; 
  }
}
.icon-location_on {
  &:before {
    content: $icon-location_on; 
  }
}
.icon-message {
  &:before {
    content: $icon-message; 
  }
}
.icon-chat_bubble {
  &:before {
    content: $icon-chat_bubble; 
  }
}
.icon-chat_bubble_outline {
  &:before {
    content: $icon-chat_bubble_outline; 
  }
}
.icon-no_sim {
  &:before {
    content: $icon-no_sim; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-portable_wifi_off {
  &:before {
    content: $icon-portable_wifi_off; 
  }
}
.icon-contact_phone {
  &:before {
    content: $icon-contact_phone; 
  }
}
.icon-contact_mail {
  &:before {
    content: $icon-contact_mail; 
  }
}
.icon-ring_volume {
  &:before {
    content: $icon-ring_volume; 
  }
}
.icon-speaker_phone {
  &:before {
    content: $icon-speaker_phone; 
  }
}
.icon-stay_current_landscape {
  &:before {
    content: $icon-stay_current_landscape; 
  }
}
.icon-stay_current_portrait {
  &:before {
    content: $icon-stay_current_portrait; 
  }
}
.icon-swap_calls {
  &:before {
    content: $icon-swap_calls; 
  }
}
.icon-textsms {
  &:before {
    content: $icon-textsms; 
  }
}
.icon-voicemail {
  &:before {
    content: $icon-voicemail; 
  }
}
.icon-vpn_key {
  &:before {
    content: $icon-vpn_key; 
  }
}
.icon-phonelink_erase {
  &:before {
    content: $icon-phonelink_erase; 
  }
}
.icon-phonelink_lock {
  &:before {
    content: $icon-phonelink_lock; 
  }
}
.icon-phonelink_ring {
  &:before {
    content: $icon-phonelink_ring; 
  }
}
.icon-phonelink_setup {
  &:before {
    content: $icon-phonelink_setup; 
  }
}
.icon-present_to_all {
  &:before {
    content: $icon-present_to_all; 
  }
}
.icon-import_contacts {
  &:before {
    content: $icon-import_contacts; 
  }
}
.icon-mail_outline {
  &:before {
    content: $icon-mail_outline; 
  }
}
.icon-screen_share {
  &:before {
    content: $icon-screen_share; 
  }
}
.icon-stop_screen_share {
  &:before {
    content: $icon-stop_screen_share; 
  }
}
.icon-call_missed_outgoing {
  &:before {
    content: $icon-call_missed_outgoing; 
  }
}
.icon-rss_feed {
  &:before {
    content: $icon-rss_feed; 
  }
}
.icon-alternate_email {
  &:before {
    content: $icon-alternate_email; 
  }
}
.icon-mobile_screen_share {
  &:before {
    content: $icon-mobile_screen_share; 
  }
}
.icon-add_call {
  &:before {
    content: $icon-add_call; 
  }
}
.icon-cancel_presentation {
  &:before {
    content: $icon-cancel_presentation; 
  }
}
.icon-pause_presentation {
  &:before {
    content: $icon-pause_presentation; 
  }
}
.icon-unsubscribe {
  &:before {
    content: $icon-unsubscribe; 
  }
}
.icon-cell_wifi {
  &:before {
    content: $icon-cell_wifi; 
  }
}
.icon-sentiment_satisfied_alt {
  &:before {
    content: $icon-sentiment_satisfied_alt; 
  }
}
.icon-list_alt {
  &:before {
    content: $icon-list_alt; 
  }
}
.icon-domain_disabled {
  &:before {
    content: $icon-domain_disabled; 
  }
}
.icon-lightbulb {
  &:before {
    content: $icon-lightbulb; 
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-add_box {
  &:before {
    content: $icon-add_box; 
  }
}
.icon-add_circle {
  &:before {
    content: $icon-add_circle; 
  }
}
.icon-add_circle_outline {
  &:before {
    content: $icon-add_circle_outline; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-backspace {
  &:before {
    content: $icon-backspace; 
  }
}
.icon-block {
  &:before {
    content: $icon-block; 
  }
}
.icon-clear {
  &:before {
    content: $icon-clear; 
  }
}
.icon-content_copy {
  &:before {
    content: $icon-content_copy; 
  }
}
.icon-content_cut {
  &:before {
    content: $icon-content_cut; 
  }
}
.icon-content_paste {
  &:before {
    content: $icon-content_paste; 
  }
}
.icon-create {
  &:before {
    content: $icon-create; 
  }
}
.icon-drafts {
  &:before {
    content: $icon-drafts; 
  }
}
.icon-filter_list {
  &:before {
    content: $icon-filter_list; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-gesture {
  &:before {
    content: $icon-gesture; 
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-redo {
  &:before {
    content: $icon-redo; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-remove_circle {
  &:before {
    content: $icon-remove_circle; 
  }
}
.icon-remove_circle_outline {
  &:before {
    content: $icon-remove_circle_outline; 
  }
}
.icon-reply {
  &:before {
    content: $icon-reply; 
  }
}
.icon-reply_all {
  &:before {
    content: $icon-reply_all; 
  }
}
.icon-report {
  &:before {
    content: $icon-report; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-select_all {
  &:before {
    content: $icon-select_all; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-text_format {
  &:before {
    content: $icon-text_format; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-font_download {
  &:before {
    content: $icon-font_download; 
  }
}
.icon-move_to_inbox {
  &:before {
    content: $icon-move_to_inbox; 
  }
}
.icon-unarchive {
  &:before {
    content: $icon-unarchive; 
  }
}
.icon-next_week {
  &:before {
    content: $icon-next_week; 
  }
}
.icon-weekend {
  &:before {
    content: $icon-weekend; 
  }
}
.icon-delete_sweep {
  &:before {
    content: $icon-delete_sweep; 
  }
}
.icon-low_priority {
  &:before {
    content: $icon-low_priority; 
  }
}
.icon-outlined_flag {
  &:before {
    content: $icon-outlined_flag; 
  }
}
.icon-link_off {
  &:before {
    content: $icon-link_off; 
  }
}
.icon-report_off {
  &:before {
    content: $icon-report_off; 
  }
}
.icon-save_alt {
  &:before {
    content: $icon-save_alt; 
  }
}
.icon-ballot {
  &:before {
    content: $icon-ballot; 
  }
}
.icon-file_copy {
  &:before {
    content: $icon-file_copy; 
  }
}
.icon-how_to_reg {
  &:before {
    content: $icon-how_to_reg; 
  }
}
.icon-how_to_vote {
  &:before {
    content: $icon-how_to_vote; 
  }
}
.icon-waves {
  &:before {
    content: $icon-waves; 
  }
}
.icon-where_to_vote {
  &:before {
    content: $icon-where_to_vote; 
  }
}
.icon-add_link {
  &:before {
    content: $icon-add_link; 
  }
}
.icon-inventory {
  &:before {
    content: $icon-inventory; 
  }
}
.icon-access_alarm {
  &:before {
    content: $icon-access_alarm; 
  }
}
.icon-access_alarms {
  &:before {
    content: $icon-access_alarms; 
  }
}
.icon-access_time {
  &:before {
    content: $icon-access_time; 
  }
}
.icon-add_alarm {
  &:before {
    content: $icon-add_alarm; 
  }
}
.icon-airplanemode_off {
  &:before {
    content: $icon-airplanemode_off; 
  }
}
.icon-airplanemode_on {
  &:before {
    content: $icon-airplanemode_on; 
  }
}
.icon-battery_alert {
  &:before {
    content: $icon-battery_alert; 
  }
}
.icon-battery_charging_full {
  &:before {
    content: $icon-battery_charging_full; 
  }
}
.icon-battery_full {
  &:before {
    content: $icon-battery_full; 
  }
}
.icon-battery_unknown {
  &:before {
    content: $icon-battery_unknown; 
  }
}
.icon-bluetooth {
  &:before {
    content: $icon-bluetooth; 
  }
}
.icon-bluetooth_connected {
  &:before {
    content: $icon-bluetooth_connected; 
  }
}
.icon-bluetooth_disabled {
  &:before {
    content: $icon-bluetooth_disabled; 
  }
}
.icon-bluetooth_searching {
  &:before {
    content: $icon-bluetooth_searching; 
  }
}
.icon-brightness_auto {
  &:before {
    content: $icon-brightness_auto; 
  }
}
.icon-brightness_high {
  &:before {
    content: $icon-brightness_high; 
  }
}
.icon-brightness_low {
  &:before {
    content: $icon-brightness_low; 
  }
}
.icon-brightness_medium {
  &:before {
    content: $icon-brightness_medium; 
  }
}
.icon-data_usage {
  &:before {
    content: $icon-data_usage; 
  }
}
.icon-developer_mode {
  &:before {
    content: $icon-developer_mode; 
  }
}
.icon-devices {
  &:before {
    content: $icon-devices; 
  }
}
.icon-dvr {
  &:before {
    content: $icon-dvr; 
  }
}
.icon-gps_fixed {
  &:before {
    content: $icon-gps_fixed; 
  }
}
.icon-gps_not_fixed {
  &:before {
    content: $icon-gps_not_fixed; 
  }
}
.icon-gps_off {
  &:before {
    content: $icon-gps_off; 
  }
}
.icon-graphic_eq {
  &:before {
    content: $icon-graphic_eq; 
  }
}
.icon-network_cell {
  &:before {
    content: $icon-network_cell; 
  }
}
.icon-network_wifi {
  &:before {
    content: $icon-network_wifi; 
  }
}
.icon-nfc {
  &:before {
    content: $icon-nfc; 
  }
}
.icon-now_wallpaper {
  &:before {
    content: $icon-now_wallpaper; 
  }
}
.icon-now_widgets {
  &:before {
    content: $icon-now_widgets; 
  }
}
.icon-screen_lock_landscape {
  &:before {
    content: $icon-screen_lock_landscape; 
  }
}
.icon-screen_lock_portrait {
  &:before {
    content: $icon-screen_lock_portrait; 
  }
}
.icon-screen_lock_rotation {
  &:before {
    content: $icon-screen_lock_rotation; 
  }
}
.icon-screen_rotation {
  &:before {
    content: $icon-screen_rotation; 
  }
}
.icon-sd_storage {
  &:before {
    content: $icon-sd_storage; 
  }
}
.icon-settings_system_daydream {
  &:before {
    content: $icon-settings_system_daydream; 
  }
}
.icon-signal_cellular_4_bar {
  &:before {
    content: $icon-signal_cellular_4_bar; 
  }
}
.icon-signal_cellular_connected_no_internet_4_bar {
  &:before {
    content: $icon-signal_cellular_connected_no_internet_4_bar; 
  }
}
.icon-signal_cellular_null {
  &:before {
    content: $icon-signal_cellular_null; 
  }
}
.icon-signal_cellular_off {
  &:before {
    content: $icon-signal_cellular_off; 
  }
}
.icon-signal_wifi_4_bar {
  &:before {
    content: $icon-signal_wifi_4_bar; 
  }
}
.icon-signal_wifi_4_bar_lock {
  &:before {
    content: $icon-signal_wifi_4_bar_lock; 
  }
}
.icon-signal_wifi_off {
  &:before {
    content: $icon-signal_wifi_off; 
  }
}
.icon-storage {
  &:before {
    content: $icon-storage; 
  }
}
.icon-usb {
  &:before {
    content: $icon-usb; 
  }
}
.icon-wifi_lock {
  &:before {
    content: $icon-wifi_lock; 
  }
}
.icon-wifi_tethering {
  &:before {
    content: $icon-wifi_tethering; 
  }
}
.icon-add_to_home_screen {
  &:before {
    content: $icon-add_to_home_screen; 
  }
}
.icon-device_thermostat {
  &:before {
    content: $icon-device_thermostat; 
  }
}
.icon-mobile_friendly {
  &:before {
    content: $icon-mobile_friendly; 
  }
}
.icon-mobile_off {
  &:before {
    content: $icon-mobile_off; 
  }
}
.icon-signal_cellular_alt {
  &:before {
    content: $icon-signal_cellular_alt; 
  }
}
.icon-attach_file {
  &:before {
    content: $icon-attach_file; 
  }
}
.icon-attach_money {
  &:before {
    content: $icon-attach_money; 
  }
}
.icon-border_all {
  &:before {
    content: $icon-border_all; 
  }
}
.icon-border_bottom {
  &:before {
    content: $icon-border_bottom; 
  }
}
.icon-border_clear {
  &:before {
    content: $icon-border_clear; 
  }
}
.icon-border_color {
  &:before {
    content: $icon-border_color; 
  }
}
.icon-border_horizontal {
  &:before {
    content: $icon-border_horizontal; 
  }
}
.icon-border_inner {
  &:before {
    content: $icon-border_inner; 
  }
}
.icon-border_left {
  &:before {
    content: $icon-border_left; 
  }
}
.icon-border_outer {
  &:before {
    content: $icon-border_outer; 
  }
}
.icon-border_right {
  &:before {
    content: $icon-border_right; 
  }
}
.icon-border_style {
  &:before {
    content: $icon-border_style; 
  }
}
.icon-border_top {
  &:before {
    content: $icon-border_top; 
  }
}
.icon-border_vertical {
  &:before {
    content: $icon-border_vertical; 
  }
}
.icon-format_align_center {
  &:before {
    content: $icon-format_align_center; 
  }
}
.icon-format_align_justify {
  &:before {
    content: $icon-format_align_justify; 
  }
}
.icon-format_align_left {
  &:before {
    content: $icon-format_align_left; 
  }
}
.icon-format_align_right {
  &:before {
    content: $icon-format_align_right; 
  }
}
.icon-format_bold {
  &:before {
    content: $icon-format_bold; 
  }
}
.icon-format_clear {
  &:before {
    content: $icon-format_clear; 
  }
}
.icon-format_color_fill {
  &:before {
    content: $icon-format_color_fill; 
  }
}
.icon-format_color_reset {
  &:before {
    content: $icon-format_color_reset; 
  }
}
.icon-format_color_text {
  &:before {
    content: $icon-format_color_text; 
  }
}
.icon-format_indent_decrease {
  &:before {
    content: $icon-format_indent_decrease; 
  }
}
.icon-format_indent_increase {
  &:before {
    content: $icon-format_indent_increase; 
  }
}
.icon-format_italic {
  &:before {
    content: $icon-format_italic; 
  }
}
.icon-format_line_spacing {
  &:before {
    content: $icon-format_line_spacing; 
  }
}
.icon-format_list_bulleted {
  &:before {
    content: $icon-format_list_bulleted; 
  }
}
.icon-format_list_numbered {
  &:before {
    content: $icon-format_list_numbered; 
  }
}
.icon-format_paint {
  &:before {
    content: $icon-format_paint; 
  }
}
.icon-format_quote {
  &:before {
    content: $icon-format_quote; 
  }
}
.icon-format_size {
  &:before {
    content: $icon-format_size; 
  }
}
.icon-format_strikethrough {
  &:before {
    content: $icon-format_strikethrough; 
  }
}
.icon-format_textdirection_l_to_r {
  &:before {
    content: $icon-format_textdirection_l_to_r; 
  }
}
.icon-format_textdirection_r_to_l {
  &:before {
    content: $icon-format_textdirection_r_to_l; 
  }
}
.icon-format_underlined {
  &:before {
    content: $icon-format_underlined; 
  }
}
.icon-functions {
  &:before {
    content: $icon-functions; 
  }
}
.icon-insert_chart {
  &:before {
    content: $icon-insert_chart; 
  }
}
.icon-insert_comment {
  &:before {
    content: $icon-insert_comment; 
  }
}
.icon-insert_drive_file {
  &:before {
    content: $icon-insert_drive_file; 
  }
}
.icon-insert_emoticon {
  &:before {
    content: $icon-insert_emoticon; 
  }
}
.icon-insert_invitation {
  &:before {
    content: $icon-insert_invitation; 
  }
}
.icon-insert_photo {
  &:before {
    content: $icon-insert_photo; 
  }
}
.icon-mode_comment {
  &:before {
    content: $icon-mode_comment; 
  }
}
.icon-publish {
  &:before {
    content: $icon-publish; 
  }
}
.icon-space_bar {
  &:before {
    content: $icon-space_bar; 
  }
}
.icon-strikethrough_s {
  &:before {
    content: $icon-strikethrough_s; 
  }
}
.icon-vertical_align_bottom {
  &:before {
    content: $icon-vertical_align_bottom; 
  }
}
.icon-vertical_align_center {
  &:before {
    content: $icon-vertical_align_center; 
  }
}
.icon-vertical_align_top {
  &:before {
    content: $icon-vertical_align_top; 
  }
}
.icon-wrap_text {
  &:before {
    content: $icon-wrap_text; 
  }
}
.icon-money_off {
  &:before {
    content: $icon-money_off; 
  }
}
.icon-drag_handle {
  &:before {
    content: $icon-drag_handle; 
  }
}
.icon-format_shapes {
  &:before {
    content: $icon-format_shapes; 
  }
}
.icon-highlight {
  &:before {
    content: $icon-highlight; 
  }
}
.icon-linear_scale {
  &:before {
    content: $icon-linear_scale; 
  }
}
.icon-short_text {
  &:before {
    content: $icon-short_text; 
  }
}
.icon-text_fields {
  &:before {
    content: $icon-text_fields; 
  }
}
.icon-monetization_on {
  &:before {
    content: $icon-monetization_on; 
  }
}
.icon-title {
  &:before {
    content: $icon-title; 
  }
}
.icon-table_chart {
  &:before {
    content: $icon-table_chart; 
  }
}
.icon-add_comment {
  &:before {
    content: $icon-add_comment; 
  }
}
.icon-format_list_numbered_rtl {
  &:before {
    content: $icon-format_list_numbered_rtl; 
  }
}
.icon-scatter_plot {
  &:before {
    content: $icon-scatter_plot; 
  }
}
.icon-score {
  &:before {
    content: $icon-score; 
  }
}
.icon-insert_chart_outlined {
  &:before {
    content: $icon-insert_chart_outlined; 
  }
}
.icon-bar_chart {
  &:before {
    content: $icon-bar_chart; 
  }
}
.icon-notes {
  &:before {
    content: $icon-notes; 
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment; 
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud; 
  }
}
.icon-cloud_circle {
  &:before {
    content: $icon-cloud_circle; 
  }
}
.icon-cloud_done {
  &:before {
    content: $icon-cloud_done; 
  }
}
.icon-cloud_download {
  &:before {
    content: $icon-cloud_download; 
  }
}
.icon-cloud_off {
  &:before {
    content: $icon-cloud_off; 
  }
}
.icon-cloud_queue {
  &:before {
    content: $icon-cloud_queue; 
  }
}
.icon-cloud_upload {
  &:before {
    content: $icon-cloud_upload; 
  }
}
.icon-file_download {
  &:before {
    content: $icon-file_download; 
  }
}
.icon-file_upload {
  &:before {
    content: $icon-file_upload; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder_open {
  &:before {
    content: $icon-folder_open; 
  }
}
.icon-folder_shared {
  &:before {
    content: $icon-folder_shared; 
  }
}
.icon-create_new_folder {
  &:before {
    content: $icon-create_new_folder; 
  }
}
.icon-cast {
  &:before {
    content: $icon-cast; 
  }
}
.icon-cast_connected {
  &:before {
    content: $icon-cast_connected; 
  }
}
.icon-computer {
  &:before {
    content: $icon-computer; 
  }
}
.icon-desktop_mac {
  &:before {
    content: $icon-desktop_mac; 
  }
}
.icon-desktop_windows {
  &:before {
    content: $icon-desktop_windows; 
  }
}
.icon-developer_board {
  &:before {
    content: $icon-developer_board; 
  }
}
.icon-dock {
  &:before {
    content: $icon-dock; 
  }
}
.icon-headset {
  &:before {
    content: $icon-headset; 
  }
}
.icon-headset_mic {
  &:before {
    content: $icon-headset_mic; 
  }
}
.icon-keyboard {
  &:before {
    content: $icon-keyboard; 
  }
}
.icon-keyboard_arrow_down {
  &:before {
    content: $icon-keyboard_arrow_down; 
  }
}
.icon-keyboard_arrow_left {
  &:before {
    content: $icon-keyboard_arrow_left; 
  }
}
.icon-keyboard_arrow_right {
  &:before {
    content: $icon-keyboard_arrow_right; 
  }
}
.icon-keyboard_arrow_up {
  &:before {
    content: $icon-keyboard_arrow_up; 
  }
}
.icon-keyboard_backspace {
  &:before {
    content: $icon-keyboard_backspace; 
  }
}
.icon-keyboard_capslock {
  &:before {
    content: $icon-keyboard_capslock; 
  }
}
.icon-keyboard_hide {
  &:before {
    content: $icon-keyboard_hide; 
  }
}
.icon-keyboard_return {
  &:before {
    content: $icon-keyboard_return; 
  }
}
.icon-keyboard_tab {
  &:before {
    content: $icon-keyboard_tab; 
  }
}
.icon-keyboard_voice {
  &:before {
    content: $icon-keyboard_voice; 
  }
}
.icon-laptop_chromebook {
  &:before {
    content: $icon-laptop_chromebook; 
  }
}
.icon-laptop_mac {
  &:before {
    content: $icon-laptop_mac; 
  }
}
.icon-laptop_windows {
  &:before {
    content: $icon-laptop_windows; 
  }
}
.icon-memory {
  &:before {
    content: $icon-memory; 
  }
}
.icon-mouse {
  &:before {
    content: $icon-mouse; 
  }
}
.icon-phone_android {
  &:before {
    content: $icon-phone_android; 
  }
}
.icon-phone_iphone {
  &:before {
    content: $icon-phone_iphone; 
  }
}
.icon-phonelink_off {
  &:before {
    content: $icon-phonelink_off; 
  }
}
.icon-router {
  &:before {
    content: $icon-router; 
  }
}
.icon-scanner {
  &:before {
    content: $icon-scanner; 
  }
}
.icon-security {
  &:before {
    content: $icon-security; 
  }
}
.icon-sim_card {
  &:before {
    content: $icon-sim_card; 
  }
}
.icon-speaker {
  &:before {
    content: $icon-speaker; 
  }
}
.icon-speaker_group {
  &:before {
    content: $icon-speaker_group; 
  }
}
.icon-tablet {
  &:before {
    content: $icon-tablet; 
  }
}
.icon-tablet_android {
  &:before {
    content: $icon-tablet_android; 
  }
}
.icon-tablet_mac {
  &:before {
    content: $icon-tablet_mac; 
  }
}
.icon-toys {
  &:before {
    content: $icon-toys; 
  }
}
.icon-tv {
  &:before {
    content: $icon-tv; 
  }
}
.icon-watch {
  &:before {
    content: $icon-watch; 
  }
}
.icon-device_hub {
  &:before {
    content: $icon-device_hub; 
  }
}
.icon-power_input {
  &:before {
    content: $icon-power_input; 
  }
}
.icon-devices_other {
  &:before {
    content: $icon-devices_other; 
  }
}
.icon-videogame_asset {
  &:before {
    content: $icon-videogame_asset; 
  }
}
.icon-device_unknown {
  &:before {
    content: $icon-device_unknown; 
  }
}
.icon-headset_off {
  &:before {
    content: $icon-headset_off; 
  }
}
.icon-adjust {
  &:before {
    content: $icon-adjust; 
  }
}
.icon-assistant {
  &:before {
    content: $icon-assistant; 
  }
}
.icon-audiotrack {
  &:before {
    content: $icon-audiotrack; 
  }
}
.icon-blur_circular {
  &:before {
    content: $icon-blur_circular; 
  }
}
.icon-blur_linear {
  &:before {
    content: $icon-blur_linear; 
  }
}
.icon-blur_off {
  &:before {
    content: $icon-blur_off; 
  }
}
.icon-blur_on {
  &:before {
    content: $icon-blur_on; 
  }
}
.icon-brightness_1 {
  &:before {
    content: $icon-brightness_1; 
  }
}
.icon-brightness_2 {
  &:before {
    content: $icon-brightness_2; 
  }
}
.icon-brightness_3 {
  &:before {
    content: $icon-brightness_3; 
  }
}
.icon-brightness_4 {
  &:before {
    content: $icon-brightness_4; 
  }
}
.icon-broken_image {
  &:before {
    content: $icon-broken_image; 
  }
}
.icon-brush {
  &:before {
    content: $icon-brush; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-camera_alt {
  &:before {
    content: $icon-camera_alt; 
  }
}
.icon-camera_front {
  &:before {
    content: $icon-camera_front; 
  }
}
.icon-camera_rear {
  &:before {
    content: $icon-camera_rear; 
  }
}
.icon-camera_roll {
  &:before {
    content: $icon-camera_roll; 
  }
}
.icon-center_focus_strong {
  &:before {
    content: $icon-center_focus_strong; 
  }
}
.icon-center_focus_weak {
  &:before {
    content: $icon-center_focus_weak; 
  }
}
.icon-collections {
  &:before {
    content: $icon-collections; 
  }
}
.icon-color_lens {
  &:before {
    content: $icon-color_lens; 
  }
}
.icon-colorize {
  &:before {
    content: $icon-colorize; 
  }
}
.icon-compare {
  &:before {
    content: $icon-compare; 
  }
}
.icon-control_point_duplicate {
  &:before {
    content: $icon-control_point_duplicate; 
  }
}
.icon-crop_16_9 {
  &:before {
    content: $icon-crop_16_9; 
  }
}
.icon-crop_3_2 {
  &:before {
    content: $icon-crop_3_2; 
  }
}
.icon-crop {
  &:before {
    content: $icon-crop; 
  }
}
.icon-crop_5_4 {
  &:before {
    content: $icon-crop_5_4; 
  }
}
.icon-crop_7_5 {
  &:before {
    content: $icon-crop_7_5; 
  }
}
.icon-crop_din {
  &:before {
    content: $icon-crop_din; 
  }
}
.icon-crop_free {
  &:before {
    content: $icon-crop_free; 
  }
}
.icon-crop_original {
  &:before {
    content: $icon-crop_original; 
  }
}
.icon-crop_portrait {
  &:before {
    content: $icon-crop_portrait; 
  }
}
.icon-crop_square {
  &:before {
    content: $icon-crop_square; 
  }
}
.icon-dehaze {
  &:before {
    content: $icon-dehaze; 
  }
}
.icon-details {
  &:before {
    content: $icon-details; 
  }
}
.icon-exposure {
  &:before {
    content: $icon-exposure; 
  }
}
.icon-exposure_neg_1 {
  &:before {
    content: $icon-exposure_neg_1; 
  }
}
.icon-exposure_neg_2 {
  &:before {
    content: $icon-exposure_neg_2; 
  }
}
.icon-exposure_plus_1 {
  &:before {
    content: $icon-exposure_plus_1; 
  }
}
.icon-exposure_plus_2 {
  &:before {
    content: $icon-exposure_plus_2; 
  }
}
.icon-exposure_zero {
  &:before {
    content: $icon-exposure_zero; 
  }
}
.icon-filter_1 {
  &:before {
    content: $icon-filter_1; 
  }
}
.icon-filter_2 {
  &:before {
    content: $icon-filter_2; 
  }
}
.icon-filter_3 {
  &:before {
    content: $icon-filter_3; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-filter_4 {
  &:before {
    content: $icon-filter_4; 
  }
}
.icon-filter_5 {
  &:before {
    content: $icon-filter_5; 
  }
}
.icon-filter_6 {
  &:before {
    content: $icon-filter_6; 
  }
}
.icon-filter_7 {
  &:before {
    content: $icon-filter_7; 
  }
}
.icon-filter_8 {
  &:before {
    content: $icon-filter_8; 
  }
}
.icon-filter_9 {
  &:before {
    content: $icon-filter_9; 
  }
}
.icon-filter_9_plus {
  &:before {
    content: $icon-filter_9_plus; 
  }
}
.icon-filter_b_and_w {
  &:before {
    content: $icon-filter_b_and_w; 
  }
}
.icon-filter_center_focus {
  &:before {
    content: $icon-filter_center_focus; 
  }
}
.icon-filter_drama {
  &:before {
    content: $icon-filter_drama; 
  }
}
.icon-filter_frames {
  &:before {
    content: $icon-filter_frames; 
  }
}
.icon-filter_hdr {
  &:before {
    content: $icon-filter_hdr; 
  }
}
.icon-filter_none {
  &:before {
    content: $icon-filter_none; 
  }
}
.icon-filter_tilt_shift {
  &:before {
    content: $icon-filter_tilt_shift; 
  }
}
.icon-filter_vintage {
  &:before {
    content: $icon-filter_vintage; 
  }
}
.icon-flare {
  &:before {
    content: $icon-flare; 
  }
}
.icon-flash_auto {
  &:before {
    content: $icon-flash_auto; 
  }
}
.icon-flash_off {
  &:before {
    content: $icon-flash_off; 
  }
}
.icon-flash_on {
  &:before {
    content: $icon-flash_on; 
  }
}
.icon-flip {
  &:before {
    content: $icon-flip; 
  }
}
.icon-gradient {
  &:before {
    content: $icon-gradient; 
  }
}
.icon-grain {
  &:before {
    content: $icon-grain; 
  }
}
.icon-grid_off {
  &:before {
    content: $icon-grid_off; 
  }
}
.icon-grid_on {
  &:before {
    content: $icon-grid_on; 
  }
}
.icon-hdr_off {
  &:before {
    content: $icon-hdr_off; 
  }
}
.icon-hdr_on {
  &:before {
    content: $icon-hdr_on; 
  }
}
.icon-hdr_strong {
  &:before {
    content: $icon-hdr_strong; 
  }
}
.icon-hdr_weak {
  &:before {
    content: $icon-hdr_weak; 
  }
}
.icon-healing {
  &:before {
    content: $icon-healing; 
  }
}
.icon-image_aspect_ratio {
  &:before {
    content: $icon-image_aspect_ratio; 
  }
}
.icon-iso {
  &:before {
    content: $icon-iso; 
  }
}
.icon-leak_add {
  &:before {
    content: $icon-leak_add; 
  }
}
.icon-leak_remove {
  &:before {
    content: $icon-leak_remove; 
  }
}
.icon-lens {
  &:before {
    content: $icon-lens; 
  }
}
.icon-looks_3 {
  &:before {
    content: $icon-looks_3; 
  }
}
.icon-looks {
  &:before {
    content: $icon-looks; 
  }
}
.icon-looks_4 {
  &:before {
    content: $icon-looks_4; 
  }
}
.icon-looks_5 {
  &:before {
    content: $icon-looks_5; 
  }
}
.icon-looks_6 {
  &:before {
    content: $icon-looks_6; 
  }
}
.icon-looks_one {
  &:before {
    content: $icon-looks_one; 
  }
}
.icon-looks_two {
  &:before {
    content: $icon-looks_two; 
  }
}
.icon-loupe {
  &:before {
    content: $icon-loupe; 
  }
}
.icon-monochrome_photos {
  &:before {
    content: $icon-monochrome_photos; 
  }
}
.icon-music_note {
  &:before {
    content: $icon-music_note; 
  }
}
.icon-nature {
  &:before {
    content: $icon-nature; 
  }
}
.icon-nature_people {
  &:before {
    content: $icon-nature_people; 
  }
}
.icon-navigate_before {
  &:before {
    content: $icon-navigate_before; 
  }
}
.icon-navigate_next {
  &:before {
    content: $icon-navigate_next; 
  }
}
.icon-panorama {
  &:before {
    content: $icon-panorama; 
  }
}
.icon-panorama_fisheye {
  &:before {
    content: $icon-panorama_fisheye; 
  }
}
.icon-panorama_horizontal {
  &:before {
    content: $icon-panorama_horizontal; 
  }
}
.icon-panorama_vertical {
  &:before {
    content: $icon-panorama_vertical; 
  }
}
.icon-panorama_wide_angle {
  &:before {
    content: $icon-panorama_wide_angle; 
  }
}
.icon-photo_album {
  &:before {
    content: $icon-photo_album; 
  }
}
.icon-picture_as_pdf {
  &:before {
    content: $icon-picture_as_pdf; 
  }
}
.icon-portrait {
  &:before {
    content: $icon-portrait; 
  }
}
.icon-remove_red_eye {
  &:before {
    content: $icon-remove_red_eye; 
  }
}
.icon-rotate_90_degrees_ccw {
  &:before {
    content: $icon-rotate_90_degrees_ccw; 
  }
}
.icon-rotate_left {
  &:before {
    content: $icon-rotate_left; 
  }
}
.icon-rotate_right {
  &:before {
    content: $icon-rotate_right; 
  }
}
.icon-slideshow {
  &:before {
    content: $icon-slideshow; 
  }
}
.icon-straighten {
  &:before {
    content: $icon-straighten; 
  }
}
.icon-style {
  &:before {
    content: $icon-style; 
  }
}
.icon-switch_camera {
  &:before {
    content: $icon-switch_camera; 
  }
}
.icon-switch_video {
  &:before {
    content: $icon-switch_video; 
  }
}
.icon-texture {
  &:before {
    content: $icon-texture; 
  }
}
.icon-timelapse {
  &:before {
    content: $icon-timelapse; 
  }
}
.icon-timer_10 {
  &:before {
    content: $icon-timer_10; 
  }
}
.icon-timer_3 {
  &:before {
    content: $icon-timer_3; 
  }
}
.icon-timer {
  &:before {
    content: $icon-timer; 
  }
}
.icon-timer_off {
  &:before {
    content: $icon-timer_off; 
  }
}
.icon-tonality {
  &:before {
    content: $icon-tonality; 
  }
}
.icon-transform {
  &:before {
    content: $icon-transform; 
  }
}
.icon-tune {
  &:before {
    content: $icon-tune; 
  }
}
.icon-view_comfy {
  &:before {
    content: $icon-view_comfy; 
  }
}
.icon-view_compact {
  &:before {
    content: $icon-view_compact; 
  }
}
.icon-wb_auto {
  &:before {
    content: $icon-wb_auto; 
  }
}
.icon-wb_cloudy {
  &:before {
    content: $icon-wb_cloudy; 
  }
}
.icon-wb_incandescent {
  &:before {
    content: $icon-wb_incandescent; 
  }
}
.icon-wb_sunny {
  &:before {
    content: $icon-wb_sunny; 
  }
}
.icon-collections_bookmark {
  &:before {
    content: $icon-collections_bookmark; 
  }
}
.icon-photo_size_select_actual {
  &:before {
    content: $icon-photo_size_select_actual; 
  }
}
.icon-photo_size_select_large {
  &:before {
    content: $icon-photo_size_select_large; 
  }
}
.icon-photo_size_select_small {
  &:before {
    content: $icon-photo_size_select_small; 
  }
}
.icon-vignette {
  &:before {
    content: $icon-vignette; 
  }
}
.icon-wb_iridescent {
  &:before {
    content: $icon-wb_iridescent; 
  }
}
.icon-crop_rotate {
  &:before {
    content: $icon-crop_rotate; 
  }
}
.icon-linked_camera {
  &:before {
    content: $icon-linked_camera; 
  }
}
.icon-add_a_photo {
  &:before {
    content: $icon-add_a_photo; 
  }
}
.icon-movie_filter {
  &:before {
    content: $icon-movie_filter; 
  }
}
.icon-photo_filter {
  &:before {
    content: $icon-photo_filter; 
  }
}
.icon-burst_mode {
  &:before {
    content: $icon-burst_mode; 
  }
}
.icon-shutter_speed {
  &:before {
    content: $icon-shutter_speed; 
  }
}
.icon-add_photo_alternate {
  &:before {
    content: $icon-add_photo_alternate; 
  }
}
.icon-image_search {
  &:before {
    content: $icon-image_search; 
  }
}
.icon-music_off {
  &:before {
    content: $icon-music_off; 
  }
}
.icon-beenhere {
  &:before {
    content: $icon-beenhere; 
  }
}
.icon-directions {
  &:before {
    content: $icon-directions; 
  }
}
.icon-directions_bike {
  &:before {
    content: $icon-directions_bike; 
  }
}
.icon-directions_bus {
  &:before {
    content: $icon-directions_bus; 
  }
}
.icon-directions_car {
  &:before {
    content: $icon-directions_car; 
  }
}
.icon-directions_ferry {
  &:before {
    content: $icon-directions_ferry; 
  }
}
.icon-directions_subway {
  &:before {
    content: $icon-directions_subway; 
  }
}
.icon-directions_train {
  &:before {
    content: $icon-directions_train; 
  }
}
.icon-directions_walk {
  &:before {
    content: $icon-directions_walk; 
  }
}
.icon-hotel {
  &:before {
    content: $icon-hotel; 
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}
.icon-layers_clear {
  &:before {
    content: $icon-layers_clear; 
  }
}
.icon-local_atm {
  &:before {
    content: $icon-local_atm; 
  }
}
.icon-local_attraction {
  &:before {
    content: $icon-local_attraction; 
  }
}
.icon-local_bar {
  &:before {
    content: $icon-local_bar; 
  }
}
.icon-local_cafe {
  &:before {
    content: $icon-local_cafe; 
  }
}
.icon-local_car_wash {
  &:before {
    content: $icon-local_car_wash; 
  }
}
.icon-local_convenience_store {
  &:before {
    content: $icon-local_convenience_store; 
  }
}
.icon-local_drink {
  &:before {
    content: $icon-local_drink; 
  }
}
.icon-local_florist {
  &:before {
    content: $icon-local_florist; 
  }
}
.icon-local_gas_station {
  &:before {
    content: $icon-local_gas_station; 
  }
}
.icon-local_grocery_store {
  &:before {
    content: $icon-local_grocery_store; 
  }
}
.icon-local_hospital {
  &:before {
    content: $icon-local_hospital; 
  }
}
.icon-local_laundry_service {
  &:before {
    content: $icon-local_laundry_service; 
  }
}
.icon-local_library {
  &:before {
    content: $icon-local_library; 
  }
}
.icon-local_mall {
  &:before {
    content: $icon-local_mall; 
  }
}
.icon-local_movies {
  &:before {
    content: $icon-local_movies; 
  }
}
.icon-local_offer {
  &:before {
    content: $icon-local_offer; 
  }
}
.icon-local_parking {
  &:before {
    content: $icon-local_parking; 
  }
}
.icon-local_pharmacy {
  &:before {
    content: $icon-local_pharmacy; 
  }
}
.icon-local_pizza {
  &:before {
    content: $icon-local_pizza; 
  }
}
.icon-local_printshop {
  &:before {
    content: $icon-local_printshop; 
  }
}
.icon-local_restaurant {
  &:before {
    content: $icon-local_restaurant; 
  }
}
.icon-local_shipping {
  &:before {
    content: $icon-local_shipping; 
  }
}
.icon-local_taxi {
  &:before {
    content: $icon-local_taxi; 
  }
}
.icon-location_history {
  &:before {
    content: $icon-location_history; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation; 
  }
}
.icon-pin_drop {
  &:before {
    content: $icon-pin_drop; 
  }
}
.icon-rate_review {
  &:before {
    content: $icon-rate_review; 
  }
}
.icon-satellite {
  &:before {
    content: $icon-satellite; 
  }
}
.icon-store_mall_directory {
  &:before {
    content: $icon-store_mall_directory; 
  }
}
.icon-traffic {
  &:before {
    content: $icon-traffic; 
  }
}
.icon-directions_run {
  &:before {
    content: $icon-directions_run; 
  }
}
.icon-add_location {
  &:before {
    content: $icon-add_location; 
  }
}
.icon-edit_location {
  &:before {
    content: $icon-edit_location; 
  }
}
.icon-near_me {
  &:before {
    content: $icon-near_me; 
  }
}
.icon-person_pin_circle {
  &:before {
    content: $icon-person_pin_circle; 
  }
}
.icon-zoom_out_map {
  &:before {
    content: $icon-zoom_out_map; 
  }
}
.icon-restaurant {
  &:before {
    content: $icon-restaurant; 
  }
}
.icon-ev_station {
  &:before {
    content: $icon-ev_station; 
  }
}
.icon-streetview {
  &:before {
    content: $icon-streetview; 
  }
}
.icon-subway {
  &:before {
    content: $icon-subway; 
  }
}
.icon-train {
  &:before {
    content: $icon-train; 
  }
}
.icon-tram {
  &:before {
    content: $icon-tram; 
  }
}
.icon-transfer_within_a_station {
  &:before {
    content: $icon-transfer_within_a_station; 
  }
}
.icon-atm {
  &:before {
    content: $icon-atm; 
  }
}
.icon-category {
  &:before {
    content: $icon-category; 
  }
}
.icon-not_listed_location {
  &:before {
    content: $icon-not_listed_location; 
  }
}
.icon-departure_board {
  &:before {
    content: $icon-departure_board; 
  }
}
.icon-360 {
  &:before {
    content: $icon-360; 
  }
}
.icon-edit_attributes {
  &:before {
    content: $icon-edit_attributes; 
  }
}
.icon-transit_enterexit {
  &:before {
    content: $icon-transit_enterexit; 
  }
}
.icon-fastfood {
  &:before {
    content: $icon-fastfood; 
  }
}
.icon-trip_origin {
  &:before {
    content: $icon-trip_origin; 
  }
}
.icon-compass_calibration {
  &:before {
    content: $icon-compass_calibration; 
  }
}
.icon-money {
  &:before {
    content: $icon-money; 
  }
}
.icon-apps {
  &:before {
    content: $icon-apps; 
  }
}
.icon-arrow_back {
  &:before {
    content: $icon-arrow_back; 
  }
}
.icon-arrow_drop_down {
  &:before {
    content: $icon-arrow_drop_down; 
  }
}
.icon-arrow_drop_down_circle {
  &:before {
    content: $icon-arrow_drop_down_circle; 
  }
}
.icon-arrow_drop_up {
  &:before {
    content: $icon-arrow_drop_up; 
  }
}
.icon-arrow_forward {
  &:before {
    content: $icon-arrow_forward; 
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-expand_less {
  &:before {
    content: $icon-expand_less; 
  }
}
.icon-expand_more {
  &:before {
    content: $icon-expand_more; 
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen; 
  }
}
.icon-fullscreen_exit {
  &:before {
    content: $icon-fullscreen_exit; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-keyboard_control {
  &:before {
    content: $icon-keyboard_control; 
  }
}
.icon-more_vert {
  &:before {
    content: $icon-more_vert; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-unfold_less {
  &:before {
    content: $icon-unfold_less; 
  }
}
.icon-unfold_more {
  &:before {
    content: $icon-unfold_more; 
  }
}
.icon-arrow_upward {
  &:before {
    content: $icon-arrow_upward; 
  }
}
.icon-subdirectory_arrow_left {
  &:before {
    content: $icon-subdirectory_arrow_left; 
  }
}
.icon-subdirectory_arrow_right {
  &:before {
    content: $icon-subdirectory_arrow_right; 
  }
}
.icon-arrow_downward {
  &:before {
    content: $icon-arrow_downward; 
  }
}
.icon-first_page {
  &:before {
    content: $icon-first_page; 
  }
}
.icon-last_page {
  &:before {
    content: $icon-last_page; 
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left; 
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right; 
  }
}
.icon-arrow_back_ios {
  &:before {
    content: $icon-arrow_back_ios; 
  }
}
.icon-arrow_forward_ios {
  &:before {
    content: $icon-arrow_forward_ios; 
  }
}
.icon-adb {
  &:before {
    content: $icon-adb; 
  }
}
.icon-disc_full {
  &:before {
    content: $icon-disc_full; 
  }
}
.icon-do_not_disturb_alt {
  &:before {
    content: $icon-do_not_disturb_alt; 
  }
}
.icon-drive_eta {
  &:before {
    content: $icon-drive_eta; 
  }
}
.icon-event_available {
  &:before {
    content: $icon-event_available; 
  }
}
.icon-event_busy {
  &:before {
    content: $icon-event_busy; 
  }
}
.icon-event_note {
  &:before {
    content: $icon-event_note; 
  }
}
.icon-folder_special {
  &:before {
    content: $icon-folder_special; 
  }
}
.icon-mms {
  &:before {
    content: $icon-mms; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-network_locked {
  &:before {
    content: $icon-network_locked; 
  }
}
.icon-phone_bluetooth_speaker {
  &:before {
    content: $icon-phone_bluetooth_speaker; 
  }
}
.icon-phone_forwarded {
  &:before {
    content: $icon-phone_forwarded; 
  }
}
.icon-phone_in_talk {
  &:before {
    content: $icon-phone_in_talk; 
  }
}
.icon-phone_locked {
  &:before {
    content: $icon-phone_locked; 
  }
}
.icon-phone_missed {
  &:before {
    content: $icon-phone_missed; 
  }
}
.icon-phone_paused {
  &:before {
    content: $icon-phone_paused; 
  }
}
.icon-sim_card_alert {
  &:before {
    content: $icon-sim_card_alert; 
  }
}
.icon-sms_failed {
  &:before {
    content: $icon-sms_failed; 
  }
}
.icon-sync_disabled {
  &:before {
    content: $icon-sync_disabled; 
  }
}
.icon-sync_problem {
  &:before {
    content: $icon-sync_problem; 
  }
}
.icon-system_update {
  &:before {
    content: $icon-system_update; 
  }
}
.icon-tap_and_play {
  &:before {
    content: $icon-tap_and_play; 
  }
}
.icon-vibration {
  &:before {
    content: $icon-vibration; 
  }
}
.icon-voice_chat {
  &:before {
    content: $icon-voice_chat; 
  }
}
.icon-vpn_lock {
  &:before {
    content: $icon-vpn_lock; 
  }
}
.icon-airline_seat_flat {
  &:before {
    content: $icon-airline_seat_flat; 
  }
}
.icon-airline_seat_flat_angled {
  &:before {
    content: $icon-airline_seat_flat_angled; 
  }
}
.icon-airline_seat_individual_suite {
  &:before {
    content: $icon-airline_seat_individual_suite; 
  }
}
.icon-airline_seat_legroom_extra {
  &:before {
    content: $icon-airline_seat_legroom_extra; 
  }
}
.icon-airline_seat_legroom_normal {
  &:before {
    content: $icon-airline_seat_legroom_normal; 
  }
}
.icon-airline_seat_legroom_reduced {
  &:before {
    content: $icon-airline_seat_legroom_reduced; 
  }
}
.icon-airline_seat_recline_extra {
  &:before {
    content: $icon-airline_seat_recline_extra; 
  }
}
.icon-airline_seat_recline_normal {
  &:before {
    content: $icon-airline_seat_recline_normal; 
  }
}
.icon-confirmation_number {
  &:before {
    content: $icon-confirmation_number; 
  }
}
.icon-live_tv {
  &:before {
    content: $icon-live_tv; 
  }
}
.icon-ondemand_video {
  &:before {
    content: $icon-ondemand_video; 
  }
}
.icon-personal_video {
  &:before {
    content: $icon-personal_video; 
  }
}
.icon-power {
  &:before {
    content: $icon-power; 
  }
}
.icon-wc {
  &:before {
    content: $icon-wc; 
  }
}
.icon-wifi {
  &:before {
    content: $icon-wifi; 
  }
}
.icon-enhanced_encryption {
  &:before {
    content: $icon-enhanced_encryption; 
  }
}
.icon-network_check {
  &:before {
    content: $icon-network_check; 
  }
}
.icon-no_encryption {
  &:before {
    content: $icon-no_encryption; 
  }
}
.icon-rv_hookup {
  &:before {
    content: $icon-rv_hookup; 
  }
}
.icon-do_not_disturb_off {
  &:before {
    content: $icon-do_not_disturb_off; 
  }
}
.icon-priority_high {
  &:before {
    content: $icon-priority_high; 
  }
}
.icon-power_off {
  &:before {
    content: $icon-power_off; 
  }
}
.icon-tv_off {
  &:before {
    content: $icon-tv_off; 
  }
}
.icon-wifi_off {
  &:before {
    content: $icon-wifi_off; 
  }
}
.icon-phone_callback {
  &:before {
    content: $icon-phone_callback; 
  }
}
.icon-pie_chart {
  &:before {
    content: $icon-pie_chart; 
  }
}
.icon-pie_chart_outlined {
  &:before {
    content: $icon-pie_chart_outlined; 
  }
}
.icon-bubble_chart {
  &:before {
    content: $icon-bubble_chart; 
  }
}
.icon-multiline_chart {
  &:before {
    content: $icon-multiline_chart; 
  }
}
.icon-show_chart {
  &:before {
    content: $icon-show_chart; 
  }
}
.icon-cake {
  &:before {
    content: $icon-cake; 
  }
}
.icon-group {
  &:before {
    content: $icon-group; 
  }
}
.icon-group_add {
  &:before {
    content: $icon-group_add; 
  }
}
.icon-location_city {
  &:before {
    content: $icon-location_city; 
  }
}
.icon-mood_bad {
  &:before {
    content: $icon-mood_bad; 
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications; 
  }
}
.icon-notifications_none {
  &:before {
    content: $icon-notifications_none; 
  }
}
.icon-notifications_off {
  &:before {
    content: $icon-notifications_off; 
  }
}
.icon-notifications_on {
  &:before {
    content: $icon-notifications_on; 
  }
}
.icon-notifications_paused {
  &:before {
    content: $icon-notifications_paused; 
  }
}
.icon-pages {
  &:before {
    content: $icon-pages; 
  }
}
.icon-party_mode {
  &:before {
    content: $icon-party_mode; 
  }
}
.icon-people_outline {
  &:before {
    content: $icon-people_outline; 
  }
}
.icon-person {
  &:before {
    content: $icon-person; 
  }
}
.icon-person_add {
  &:before {
    content: $icon-person_add; 
  }
}
.icon-person_outline {
  &:before {
    content: $icon-person_outline; 
  }
}
.icon-plus_one {
  &:before {
    content: $icon-plus_one; 
  }
}
.icon-public {
  &:before {
    content: $icon-public; 
  }
}
.icon-school {
  &:before {
    content: $icon-school; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-whatshot {
  &:before {
    content: $icon-whatshot; 
  }
}
.icon-sentiment_dissatisfied {
  &:before {
    content: $icon-sentiment_dissatisfied; 
  }
}
.icon-sentiment_neutral {
  &:before {
    content: $icon-sentiment_neutral; 
  }
}
.icon-sentiment_satisfied {
  &:before {
    content: $icon-sentiment_satisfied; 
  }
}
.icon-sentiment_very_dissatisfied {
  &:before {
    content: $icon-sentiment_very_dissatisfied; 
  }
}
.icon-sentiment_very_satisfied {
  &:before {
    content: $icon-sentiment_very_satisfied; 
  }
}
.icon-thumb_down_alt {
  &:before {
    content: $icon-thumb_down_alt; 
  }
}
.icon-thumb_up_alt {
  &:before {
    content: $icon-thumb_up_alt; 
  }
}
.icon-check_box {
  &:before {
    content: $icon-check_box; 
  }
}
.icon-check_box_outline_blank {
  &:before {
    content: $icon-check_box_outline_blank; 
  }
}
.icon-radio_button_on {
  &:before {
    content: $icon-radio_button_on; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-star_half {
  &:before {
    content: $icon-star_half; 
  }
}
.icon-star_outline {
  &:before {
    content: $icon-star_outline; 
  }
}
.icon-3d_rotation {
  &:before {
    content: $icon-3d_rotation; 
  }
}
.icon-accessibility {
  &:before {
    content: $icon-accessibility; 
  }
}
.icon-account_balance {
  &:before {
    content: $icon-account_balance; 
  }
}
.icon-account_balance_wallet {
  &:before {
    content: $icon-account_balance_wallet; 
  }
}
.icon-account_box {
  &:before {
    content: $icon-account_box; 
  }
}
.icon-account_circle {
  &:before {
    content: $icon-account_circle; 
  }
}
.icon-add_shopping_cart {
  &:before {
    content: $icon-add_shopping_cart; 
  }
}
.icon-alarm_off {
  &:before {
    content: $icon-alarm_off; 
  }
}
.icon-alarm_on {
  &:before {
    content: $icon-alarm_on; 
  }
}
.icon-android {
  &:before {
    content: $icon-android; 
  }
}
.icon-announcement {
  &:before {
    content: $icon-announcement; 
  }
}
.icon-aspect_ratio {
  &:before {
    content: $icon-aspect_ratio; 
  }
}
.icon-assignment {
  &:before {
    content: $icon-assignment; 
  }
}
.icon-assignment_ind {
  &:before {
    content: $icon-assignment_ind; 
  }
}
.icon-assignment_late {
  &:before {
    content: $icon-assignment_late; 
  }
}
.icon-assignment_return {
  &:before {
    content: $icon-assignment_return; 
  }
}
.icon-assignment_returned {
  &:before {
    content: $icon-assignment_returned; 
  }
}
.icon-assignment_turned_in {
  &:before {
    content: $icon-assignment_turned_in; 
  }
}
.icon-autorenew {
  &:before {
    content: $icon-autorenew; 
  }
}
.icon-book {
  &:before {
    content: $icon-book; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-bookmark_outline {
  &:before {
    content: $icon-bookmark_outline; 
  }
}
.icon-bug_report {
  &:before {
    content: $icon-bug_report; 
  }
}
.icon-build {
  &:before {
    content: $icon-build; 
  }
}
.icon-cached {
  &:before {
    content: $icon-cached; 
  }
}
.icon-change_history {
  &:before {
    content: $icon-change_history; 
  }
}
.icon-check_circle {
  &:before {
    content: $icon-check_circle; 
  }
}
.icon-chrome_reader_mode {
  &:before {
    content: $icon-chrome_reader_mode; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-credit_card {
  &:before {
    content: $icon-credit_card; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-description {
  &:before {
    content: $icon-description; 
  }
}
.icon-dns {
  &:before {
    content: $icon-dns; 
  }
}
.icon-done {
  &:before {
    content: $icon-done; 
  }
}
.icon-done_all {
  &:before {
    content: $icon-done_all; 
  }
}
.icon-exit_to_app {
  &:before {
    content: $icon-exit_to_app; 
  }
}
.icon-explore {
  &:before {
    content: $icon-explore; 
  }
}
.icon-extension {
  &:before {
    content: $icon-extension; 
  }
}
.icon-face {
  &:before {
    content: $icon-face; 
  }
}
.icon-favorite {
  &:before {
    content: $icon-favorite; 
  }
}
.icon-favorite_outline {
  &:before {
    content: $icon-favorite_outline; 
  }
}
.icon-find_in_page {
  &:before {
    content: $icon-find_in_page; 
  }
}
.icon-find_replace {
  &:before {
    content: $icon-find_replace; 
  }
}
.icon-flip_to_back {
  &:before {
    content: $icon-flip_to_back; 
  }
}
.icon-flip_to_front {
  &:before {
    content: $icon-flip_to_front; 
  }
}
.icon-group_work {
  &:before {
    content: $icon-group_work; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-highlight_remove {
  &:before {
    content: $icon-highlight_remove; 
  }
}
.icon-history {
  &:before {
    content: $icon-history; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-hourglass_empty {
  &:before {
    content: $icon-hourglass_empty; 
  }
}
.icon-hourglass_full {
  &:before {
    content: $icon-hourglass_full; 
  }
}
.icon-https {
  &:before {
    content: $icon-https; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-info_outline {
  &:before {
    content: $icon-info_outline; 
  }
}
.icon-input {
  &:before {
    content: $icon-input; 
  }
}
.icon-invert_colors_on {
  &:before {
    content: $icon-invert_colors_on; 
  }
}
.icon-label {
  &:before {
    content: $icon-label; 
  }
}
.icon-label_outline {
  &:before {
    content: $icon-label_outline; 
  }
}
.icon-language {
  &:before {
    content: $icon-language; 
  }
}
.icon-launch {
  &:before {
    content: $icon-launch; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-lock_open {
  &:before {
    content: $icon-lock_open; 
  }
}
.icon-lock_outline {
  &:before {
    content: $icon-lock_outline; 
  }
}
.icon-loyalty {
  &:before {
    content: $icon-loyalty; 
  }
}
.icon-markunread_mailbox {
  &:before {
    content: $icon-markunread_mailbox; 
  }
}
.icon-note_add {
  &:before {
    content: $icon-note_add; 
  }
}
.icon-open_in_browser {
  &:before {
    content: $icon-open_in_browser; 
  }
}
.icon-open_with {
  &:before {
    content: $icon-open_with; 
  }
}
.icon-pageview {
  &:before {
    content: $icon-pageview; 
  }
}
.icon-perm_camera_mic {
  &:before {
    content: $icon-perm_camera_mic; 
  }
}
.icon-perm_contact_calendar {
  &:before {
    content: $icon-perm_contact_calendar; 
  }
}
.icon-perm_data_setting {
  &:before {
    content: $icon-perm_data_setting; 
  }
}
.icon-perm_device_information {
  &:before {
    content: $icon-perm_device_information; 
  }
}
.icon-perm_media {
  &:before {
    content: $icon-perm_media; 
  }
}
.icon-perm_phone_msg {
  &:before {
    content: $icon-perm_phone_msg; 
  }
}
.icon-perm_scan_wifi {
  &:before {
    content: $icon-perm_scan_wifi; 
  }
}
.icon-picture_in_picture {
  &:before {
    content: $icon-picture_in_picture; 
  }
}
.icon-polymer {
  &:before {
    content: $icon-polymer; 
  }
}
.icon-power_settings_new {
  &:before {
    content: $icon-power_settings_new; 
  }
}
.icon-receipt {
  &:before {
    content: $icon-receipt; 
  }
}
.icon-redeem {
  &:before {
    content: $icon-redeem; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-settings_applications {
  &:before {
    content: $icon-settings_applications; 
  }
}
.icon-settings_backup_restore {
  &:before {
    content: $icon-settings_backup_restore; 
  }
}
.icon-settings_bluetooth {
  &:before {
    content: $icon-settings_bluetooth; 
  }
}
.icon-settings_cell {
  &:before {
    content: $icon-settings_cell; 
  }
}
.icon-settings_display {
  &:before {
    content: $icon-settings_display; 
  }
}
.icon-settings_ethernet {
  &:before {
    content: $icon-settings_ethernet; 
  }
}
.icon-settings_input_antenna {
  &:before {
    content: $icon-settings_input_antenna; 
  }
}
.icon-settings_input_component {
  &:before {
    content: $icon-settings_input_component; 
  }
}
.icon-settings_input_hdmi {
  &:before {
    content: $icon-settings_input_hdmi; 
  }
}
.icon-settings_input_svideo {
  &:before {
    content: $icon-settings_input_svideo; 
  }
}
.icon-settings_overscan {
  &:before {
    content: $icon-settings_overscan; 
  }
}
.icon-settings_phone {
  &:before {
    content: $icon-settings_phone; 
  }
}
.icon-settings_power {
  &:before {
    content: $icon-settings_power; 
  }
}
.icon-settings_remote {
  &:before {
    content: $icon-settings_remote; 
  }
}
.icon-settings_voice {
  &:before {
    content: $icon-settings_voice; 
  }
}
.icon-shop {
  &:before {
    content: $icon-shop; 
  }
}
.icon-shop_two {
  &:before {
    content: $icon-shop_two; 
  }
}
.icon-shopping_basket {
  &:before {
    content: $icon-shopping_basket; 
  }
}
.icon-speaker_notes {
  &:before {
    content: $icon-speaker_notes; 
  }
}
.icon-spellcheck {
  &:before {
    content: $icon-spellcheck; 
  }
}
.icon-stars {
  &:before {
    content: $icon-stars; 
  }
}
.icon-subject {
  &:before {
    content: $icon-subject; 
  }
}
.icon-supervisor_account {
  &:before {
    content: $icon-supervisor_account; 
  }
}
.icon-swap_horiz {
  &:before {
    content: $icon-swap_horiz; 
  }
}
.icon-swap_vert {
  &:before {
    content: $icon-swap_vert; 
  }
}
.icon-swap_vertical_circle {
  &:before {
    content: $icon-swap_vertical_circle; 
  }
}
.icon-system_update_tv {
  &:before {
    content: $icon-system_update_tv; 
  }
}
.icon-tab {
  &:before {
    content: $icon-tab; 
  }
}
.icon-tab_unselected {
  &:before {
    content: $icon-tab_unselected; 
  }
}
.icon-thumb_down {
  &:before {
    content: $icon-thumb_down; 
  }
}
.icon-thumb_up {
  &:before {
    content: $icon-thumb_up; 
  }
}
.icon-thumbs_up_down {
  &:before {
    content: $icon-thumbs_up_down; 
  }
}
.icon-toc {
  &:before {
    content: $icon-toc; 
  }
}
.icon-today {
  &:before {
    content: $icon-today; 
  }
}
.icon-toll {
  &:before {
    content: $icon-toll; 
  }
}
.icon-track_changes {
  &:before {
    content: $icon-track_changes; 
  }
}
.icon-translate {
  &:before {
    content: $icon-translate; 
  }
}
.icon-trending_down {
  &:before {
    content: $icon-trending_down; 
  }
}
.icon-trending_neutral {
  &:before {
    content: $icon-trending_neutral; 
  }
}
.icon-trending_up {
  &:before {
    content: $icon-trending_up; 
  }
}
.icon-verified_user {
  &:before {
    content: $icon-verified_user; 
  }
}
.icon-view_agenda {
  &:before {
    content: $icon-view_agenda; 
  }
}
.icon-view_array {
  &:before {
    content: $icon-view_array; 
  }
}
.icon-view_carousel {
  &:before {
    content: $icon-view_carousel; 
  }
}
.icon-view_column {
  &:before {
    content: $icon-view_column; 
  }
}
.icon-view_day {
  &:before {
    content: $icon-view_day; 
  }
}
.icon-view_headline {
  &:before {
    content: $icon-view_headline; 
  }
}
.icon-view_list {
  &:before {
    content: $icon-view_list; 
  }
}
.icon-view_module {
  &:before {
    content: $icon-view_module; 
  }
}
.icon-view_quilt {
  &:before {
    content: $icon-view_quilt; 
  }
}
.icon-view_stream {
  &:before {
    content: $icon-view_stream; 
  }
}
.icon-view_week {
  &:before {
    content: $icon-view_week; 
  }
}
.icon-visibility_off {
  &:before {
    content: $icon-visibility_off; 
  }
}
.icon-card_membership {
  &:before {
    content: $icon-card_membership; 
  }
}
.icon-card_travel {
  &:before {
    content: $icon-card_travel; 
  }
}
.icon-work {
  &:before {
    content: $icon-work; 
  }
}
.icon-youtube_searched_for {
  &:before {
    content: $icon-youtube_searched_for; 
  }
}
.icon-eject {
  &:before {
    content: $icon-eject; 
  }
}
.icon-camera_enhance {
  &:before {
    content: $icon-camera_enhance; 
  }
}
.icon-help_outline {
  &:before {
    content: $icon-help_outline; 
  }
}
.icon-reorder {
  &:before {
    content: $icon-reorder; 
  }
}
.icon-zoom_in {
  &:before {
    content: $icon-zoom_in; 
  }
}
.icon-zoom_out {
  &:before {
    content: $icon-zoom_out; 
  }
}
.icon-http {
  &:before {
    content: $icon-http; 
  }
}
.icon-event_seat {
  &:before {
    content: $icon-event_seat; 
  }
}
.icon-flight_land {
  &:before {
    content: $icon-flight_land; 
  }
}
.icon-flight_takeoff {
  &:before {
    content: $icon-flight_takeoff; 
  }
}
.icon-play_for_work {
  &:before {
    content: $icon-play_for_work; 
  }
}
.icon-gif {
  &:before {
    content: $icon-gif; 
  }
}
.icon-indeterminate_check_box {
  &:before {
    content: $icon-indeterminate_check_box; 
  }
}
.icon-offline_pin {
  &:before {
    content: $icon-offline_pin; 
  }
}
.icon-all_out {
  &:before {
    content: $icon-all_out; 
  }
}
.icon-copyright {
  &:before {
    content: $icon-copyright; 
  }
}
.icon-fingerprint {
  &:before {
    content: $icon-fingerprint; 
  }
}
.icon-gavel {
  &:before {
    content: $icon-gavel; 
  }
}
.icon-lightbulb_outline {
  &:before {
    content: $icon-lightbulb_outline; 
  }
}
.icon-picture_in_picture_alt {
  &:before {
    content: $icon-picture_in_picture_alt; 
  }
}
.icon-important_devices {
  &:before {
    content: $icon-important_devices; 
  }
}
.icon-touch_app {
  &:before {
    content: $icon-touch_app; 
  }
}
.icon-accessible {
  &:before {
    content: $icon-accessible; 
  }
}
.icon-compare_arrows {
  &:before {
    content: $icon-compare_arrows; 
  }
}
.icon-date_range {
  &:before {
    content: $icon-date_range; 
  }
}
.icon-donut_large {
  &:before {
    content: $icon-donut_large; 
  }
}
.icon-donut_small {
  &:before {
    content: $icon-donut_small; 
  }
}
.icon-line_style {
  &:before {
    content: $icon-line_style; 
  }
}
.icon-line_weight {
  &:before {
    content: $icon-line_weight; 
  }
}
.icon-motorcycle {
  &:before {
    content: $icon-motorcycle; 
  }
}
.icon-opacity {
  &:before {
    content: $icon-opacity; 
  }
}
.icon-pets {
  &:before {
    content: $icon-pets; 
  }
}
.icon-pregnant_woman {
  &:before {
    content: $icon-pregnant_woman; 
  }
}
.icon-record_voice_over {
  &:before {
    content: $icon-record_voice_over; 
  }
}
.icon-rounded_corner {
  &:before {
    content: $icon-rounded_corner; 
  }
}
.icon-rowing {
  &:before {
    content: $icon-rowing; 
  }
}
.icon-timeline {
  &:before {
    content: $icon-timeline; 
  }
}
.icon-update {
  &:before {
    content: $icon-update; 
  }
}
.icon-watch_later {
  &:before {
    content: $icon-watch_later; 
  }
}
.icon-pan_tool {
  &:before {
    content: $icon-pan_tool; 
  }
}
.icon-euro_symbol {
  &:before {
    content: $icon-euro_symbol; 
  }
}
.icon-g_translate {
  &:before {
    content: $icon-g_translate; 
  }
}
.icon-remove_shopping_cart {
  &:before {
    content: $icon-remove_shopping_cart; 
  }
}
.icon-restore_page {
  &:before {
    content: $icon-restore_page; 
  }
}
.icon-speaker_notes_off {
  &:before {
    content: $icon-speaker_notes_off; 
  }
}
.icon-delete_forever {
  &:before {
    content: $icon-delete_forever; 
  }
}
.icon-accessibility_new {
  &:before {
    content: $icon-accessibility_new; 
  }
}
.icon-check_circle_outline {
  &:before {
    content: $icon-check_circle_outline; 
  }
}
.icon-delete_outline {
  &:before {
    content: $icon-delete_outline; 
  }
}
.icon-done_outline {
  &:before {
    content: $icon-done_outline; 
  }
}
.icon-maximize {
  &:before {
    content: $icon-maximize; 
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize; 
  }
}
.icon-offline_bolt {
  &:before {
    content: $icon-offline_bolt; 
  }
}
.icon-swap_horizontal_circle {
  &:before {
    content: $icon-swap_horizontal_circle; 
  }
}
.icon-accessible_forward {
  &:before {
    content: $icon-accessible_forward; 
  }
}
.icon-calendar_today {
  &:before {
    content: $icon-calendar_today; 
  }
}
.icon-calendar_view_day {
  &:before {
    content: $icon-calendar_view_day; 
  }
}
.icon-label_important {
  &:before {
    content: $icon-label_important; 
  }
}
.icon-restore_from_trash {
  &:before {
    content: $icon-restore_from_trash; 
  }
}
.icon-supervised_user_circle {
  &:before {
    content: $icon-supervised_user_circle; 
  }
}
.icon-text_rotate_up {
  &:before {
    content: $icon-text_rotate_up; 
  }
}
.icon-text_rotate_vertical {
  &:before {
    content: $icon-text_rotate_vertical; 
  }
}
.icon-text_rotation_angledown {
  &:before {
    content: $icon-text_rotation_angledown; 
  }
}
.icon-text_rotation_angleup {
  &:before {
    content: $icon-text_rotation_angleup; 
  }
}
.icon-text_rotation_down {
  &:before {
    content: $icon-text_rotation_down; 
  }
}
.icon-text_rotation_none {
  &:before {
    content: $icon-text_rotation_none; 
  }
}
.icon-commute {
  &:before {
    content: $icon-commute; 
  }
}
.icon-arrow_right_alt {
  &:before {
    content: $icon-arrow_right_alt; 
  }
}
.icon-work_off {
  &:before {
    content: $icon-work_off; 
  }
}
.icon-work_outline {
  &:before {
    content: $icon-work_outline; 
  }
}
.icon-drag_indicator {
  &:before {
    content: $icon-drag_indicator; 
  }
}
.icon-horizontal_split {
  &:before {
    content: $icon-horizontal_split; 
  }
}
.icon-label_important_outline {
  &:before {
    content: $icon-label_important_outline; 
  }
}
.icon-vertical_split {
  &:before {
    content: $icon-vertical_split; 
  }
}
.icon-voice_over_off {
  &:before {
    content: $icon-voice_over_off; 
  }
}
.icon-segment {
  &:before {
    content: $icon-segment; 
  }
}
.icon-contact_support {
  &:before {
    content: $icon-contact_support; 
  }
}
.icon-compress {
  &:before {
    content: $icon-compress; 
  }
}
.icon-filter_list_alt {
  &:before {
    content: $icon-filter_list_alt; 
  }
}
.icon-expand {
  &:before {
    content: $icon-expand; 
  }
}
.icon-edit_off {
  &:before {
    content: $icon-edit_off; 
  }
}
.icon-10k {
  &:before {
    content: $icon-10k; 
  }
}
.icon-10mp {
  &:before {
    content: $icon-10mp; 
  }
}
.icon-11mp {
  &:before {
    content: $icon-11mp; 
  }
}
.icon-12mp {
  &:before {
    content: $icon-12mp; 
  }
}
.icon-13mp {
  &:before {
    content: $icon-13mp; 
  }
}
.icon-14mp {
  &:before {
    content: $icon-14mp; 
  }
}
.icon-15mp {
  &:before {
    content: $icon-15mp; 
  }
}
.icon-16mp {
  &:before {
    content: $icon-16mp; 
  }
}
.icon-17mp {
  &:before {
    content: $icon-17mp; 
  }
}
.icon-18mp {
  &:before {
    content: $icon-18mp; 
  }
}
.icon-19mp {
  &:before {
    content: $icon-19mp; 
  }
}
.icon-1k {
  &:before {
    content: $icon-1k; 
  }
}
.icon-1k_plus {
  &:before {
    content: $icon-1k_plus; 
  }
}
.icon-20mp {
  &:before {
    content: $icon-20mp; 
  }
}
.icon-21mp {
  &:before {
    content: $icon-21mp; 
  }
}
.icon-22mp {
  &:before {
    content: $icon-22mp; 
  }
}
.icon-23mp {
  &:before {
    content: $icon-23mp; 
  }
}
.icon-24mp {
  &:before {
    content: $icon-24mp; 
  }
}
.icon-2k {
  &:before {
    content: $icon-2k; 
  }
}
.icon-2k_plus {
  &:before {
    content: $icon-2k_plus; 
  }
}
.icon-2mp {
  &:before {
    content: $icon-2mp; 
  }
}
.icon-3k {
  &:before {
    content: $icon-3k; 
  }
}
.icon-3k_plus {
  &:before {
    content: $icon-3k_plus; 
  }
}
.icon-3mp {
  &:before {
    content: $icon-3mp; 
  }
}
.icon-4k_plus {
  &:before {
    content: $icon-4k_plus; 
  }
}
.icon-4mp {
  &:before {
    content: $icon-4mp; 
  }
}
.icon-5k {
  &:before {
    content: $icon-5k; 
  }
}
.icon-5k_plus {
  &:before {
    content: $icon-5k_plus; 
  }
}
.icon-5mp {
  &:before {
    content: $icon-5mp; 
  }
}
.icon-6k {
  &:before {
    content: $icon-6k; 
  }
}
.icon-6k_plus {
  &:before {
    content: $icon-6k_plus; 
  }
}
.icon-6mp {
  &:before {
    content: $icon-6mp; 
  }
}
.icon-7k {
  &:before {
    content: $icon-7k; 
  }
}
.icon-7k_plus {
  &:before {
    content: $icon-7k_plus; 
  }
}
.icon-7mp {
  &:before {
    content: $icon-7mp; 
  }
}
.icon-8k {
  &:before {
    content: $icon-8k; 
  }
}
.icon-8k_plus {
  &:before {
    content: $icon-8k_plus; 
  }
}
.icon-8mp {
  &:before {
    content: $icon-8mp; 
  }
}
.icon-9k {
  &:before {
    content: $icon-9k; 
  }
}
.icon-9k_plus {
  &:before {
    content: $icon-9k_plus; 
  }
}
.icon-9mp {
  &:before {
    content: $icon-9mp; 
  }
}
.icon-account_tree {
  &:before {
    content: $icon-account_tree; 
  }
}
.icon-add_chart {
  &:before {
    content: $icon-add_chart; 
  }
}
.icon-add_ic_call {
  &:before {
    content: $icon-add_ic_call; 
  }
}
.icon-add_moderator {
  &:before {
    content: $icon-add_moderator; 
  }
}
.icon-all_inbox {
  &:before {
    content: $icon-all_inbox; 
  }
}
.icon-approval {
  &:before {
    content: $icon-approval; 
  }
}
.icon-assistant_direction {
  &:before {
    content: $icon-assistant_direction; 
  }
}
.icon-assistant_navigation {
  &:before {
    content: $icon-assistant_navigation; 
  }
}
.icon-bookmarks {
  &:before {
    content: $icon-bookmarks; 
  }
}
.icon-bus_alert {
  &:before {
    content: $icon-bus_alert; 
  }
}
.icon-cases {
  &:before {
    content: $icon-cases; 
  }
}
.icon-circle_notifications {
  &:before {
    content: $icon-circle_notifications; 
  }
}
.icon-closed_caption_off {
  &:before {
    content: $icon-closed_caption_off; 
  }
}
.icon-connected_tv {
  &:before {
    content: $icon-connected_tv; 
  }
}
.icon-dangerous {
  &:before {
    content: $icon-dangerous; 
  }
}
.icon-dashboard_customize {
  &:before {
    content: $icon-dashboard_customize; 
  }
}
.icon-desktop_access_disabled {
  &:before {
    content: $icon-desktop_access_disabled; 
  }
}
.icon-drive_file_move_outline {
  &:before {
    content: $icon-drive_file_move_outline; 
  }
}
.icon-drive_file_rename_outline {
  &:before {
    content: $icon-drive_file_rename_outline; 
  }
}
.icon-drive_folder_upload {
  &:before {
    content: $icon-drive_folder_upload; 
  }
}
.icon-duo {
  &:before {
    content: $icon-duo; 
  }
}
.icon-explore_off {
  &:before {
    content: $icon-explore_off; 
  }
}
.icon-file_download_done {
  &:before {
    content: $icon-file_download_done; 
  }
}
.icon-rtt {
  &:before {
    content: $icon-rtt; 
  }
}
.icon-grid_view {
  &:before {
    content: $icon-grid_view; 
  }
}
.icon-hail {
  &:before {
    content: $icon-hail; 
  }
}
.icon-home_filled {
  &:before {
    content: $icon-home_filled; 
  }
}
.icon-imagesearch_roller {
  &:before {
    content: $icon-imagesearch_roller; 
  }
}
.icon-label_off {
  &:before {
    content: $icon-label_off; 
  }
}
.icon-library_add_check {
  &:before {
    content: $icon-library_add_check; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-margin {
  &:before {
    content: $icon-margin; 
  }
}
.icon-mark_as_unread {
  &:before {
    content: $icon-mark_as_unread; 
  }
}
.icon-menu_open {
  &:before {
    content: $icon-menu_open; 
  }
}
.icon-mp {
  &:before {
    content: $icon-mp; 
  }
}
.icon-offline_share {
  &:before {
    content: $icon-offline_share; 
  }
}
.icon-padding {
  &:before {
    content: $icon-padding; 
  }
}
.icon-panorama_photosphere {
  &:before {
    content: $icon-panorama_photosphere; 
  }
}
.icon-panorama_photosphere_select {
  &:before {
    content: $icon-panorama_photosphere_select; 
  }
}
.icon-person_add_disabled {
  &:before {
    content: $icon-person_add_disabled; 
  }
}
.icon-phone_disabled {
  &:before {
    content: $icon-phone_disabled; 
  }
}
.icon-phone_enabled {
  &:before {
    content: $icon-phone_enabled; 
  }
}
.icon-pivot_table_chart {
  &:before {
    content: $icon-pivot_table_chart; 
  }
}
.icon-print_disabled {
  &:before {
    content: $icon-print_disabled; 
  }
}
.icon-railway_alert {
  &:before {
    content: $icon-railway_alert; 
  }
}
.icon-recommend {
  &:before {
    content: $icon-recommend; 
  }
}
.icon-remove_done {
  &:before {
    content: $icon-remove_done; 
  }
}
.icon-remove_moderator {
  &:before {
    content: $icon-remove_moderator; 
  }
}
.icon-repeat_on {
  &:before {
    content: $icon-repeat_on; 
  }
}
.icon-repeat_one_on {
  &:before {
    content: $icon-repeat_one_on; 
  }
}
.icon-replay_circle_filled {
  &:before {
    content: $icon-replay_circle_filled; 
  }
}
.icon-reset_tv {
  &:before {
    content: $icon-reset_tv; 
  }
}
.icon-sd {
  &:before {
    content: $icon-sd; 
  }
}
.icon-shield {
  &:before {
    content: $icon-shield; 
  }
}
.icon-shuffle_on {
  &:before {
    content: $icon-shuffle_on; 
  }
}
.icon-speed {
  &:before {
    content: $icon-speed; 
  }
}
.icon-stacked_bar_chart {
  &:before {
    content: $icon-stacked_bar_chart; 
  }
}
.icon-stream {
  &:before {
    content: $icon-stream; 
  }
}
.icon-swipe {
  &:before {
    content: $icon-swipe; 
  }
}
.icon-switch_account {
  &:before {
    content: $icon-switch_account; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-thumb_down_off_alt {
  &:before {
    content: $icon-thumb_down_off_alt; 
  }
}
.icon-thumb_up_off_alt {
  &:before {
    content: $icon-thumb_up_off_alt; 
  }
}
.icon-toggle_off {
  &:before {
    content: $icon-toggle_off; 
  }
}
.icon-toggle_on {
  &:before {
    content: $icon-toggle_on; 
  }
}
.icon-two_wheeler {
  &:before {
    content: $icon-two_wheeler; 
  }
}
.icon-upload_file {
  &:before {
    content: $icon-upload_file; 
  }
}
.icon-view_in_ar {
  &:before {
    content: $icon-view_in_ar; 
  }
}
.icon-waterfall_chart {
  &:before {
    content: $icon-waterfall_chart; 
  }
}
.icon-wb_shade {
  &:before {
    content: $icon-wb_shade; 
  }
}
.icon-wb_twighlight {
  &:before {
    content: $icon-wb_twighlight; 
  }
}
.icon-home_work {
  &:before {
    content: $icon-home_work; 
  }
}
.icon-schedule_send {
  &:before {
    content: $icon-schedule_send; 
  }
}
.icon-bolt {
  &:before {
    content: $icon-bolt; 
  }
}
.icon-send_and_archive {
  &:before {
    content: $icon-send_and_archive; 
  }
}
.icon-workspaces_filled {
  &:before {
    content: $icon-workspaces_filled; 
  }
}
.icon-file_present {
  &:before {
    content: $icon-file_present; 
  }
}
.icon-workspaces_outline {
  &:before {
    content: $icon-workspaces_outline; 
  }
}
.icon-fit_screen {
  &:before {
    content: $icon-fit_screen; 
  }
}
.icon-saved_search {
  &:before {
    content: $icon-saved_search; 
  }
}
.icon-storefront {
  &:before {
    content: $icon-storefront; 
  }
}
.icon-amp_stories {
  &:before {
    content: $icon-amp_stories; 
  }
}
.icon-dynamic_feed {
  &:before {
    content: $icon-dynamic_feed; 
  }
}
.icon-euro {
  &:before {
    content: $icon-euro; 
  }
}
.icon-height {
  &:before {
    content: $icon-height; 
  }
}
.icon-policy {
  &:before {
    content: $icon-policy; 
  }
}
.icon-sync_alt {
  &:before {
    content: $icon-sync_alt; 
  }
}
.icon-menu_book {
  &:before {
    content: $icon-menu_book; 
  }
}
.icon-emoji_flags {
  &:before {
    content: $icon-emoji_flags; 
  }
}
.icon-emoji_food_beverage {
  &:before {
    content: $icon-emoji_food_beverage; 
  }
}
.icon-emoji_nature {
  &:before {
    content: $icon-emoji_nature; 
  }
}
.icon-emoji_people {
  &:before {
    content: $icon-emoji_people; 
  }
}
.icon-emoji_symbols {
  &:before {
    content: $icon-emoji_symbols; 
  }
}
.icon-emoji_transportation {
  &:before {
    content: $icon-emoji_transportation; 
  }
}
.icon-post_add {
  &:before {
    content: $icon-post_add; 
  }
}
.icon-people_alt {
  &:before {
    content: $icon-people_alt; 
  }
}
.icon-emoji_emotions {
  &:before {
    content: $icon-emoji_emotions; 
  }
}
.icon-emoji_events {
  &:before {
    content: $icon-emoji_events; 
  }
}
.icon-emoji_objects {
  &:before {
    content: $icon-emoji_objects; 
  }
}
.icon-sports_basketball {
  &:before {
    content: $icon-sports_basketball; 
  }
}
.icon-sports_cricket {
  &:before {
    content: $icon-sports_cricket; 
  }
}
.icon-sports_esports {
  &:before {
    content: $icon-sports_esports; 
  }
}
.icon-sports_football {
  &:before {
    content: $icon-sports_football; 
  }
}
.icon-sports_golf {
  &:before {
    content: $icon-sports_golf; 
  }
}
.icon-sports_hockey {
  &:before {
    content: $icon-sports_hockey; 
  }
}
.icon-sports_mma {
  &:before {
    content: $icon-sports_mma; 
  }
}
.icon-sports_motorsports {
  &:before {
    content: $icon-sports_motorsports; 
  }
}
.icon-sports_rugby {
  &:before {
    content: $icon-sports_rugby; 
  }
}
.icon-sports_soccer {
  &:before {
    content: $icon-sports_soccer; 
  }
}
.icon-sports {
  &:before {
    content: $icon-sports; 
  }
}
.icon-sports_volleyball {
  &:before {
    content: $icon-sports_volleyball; 
  }
}
.icon-sports_tennis {
  &:before {
    content: $icon-sports_tennis; 
  }
}
.icon-sports_handball {
  &:before {
    content: $icon-sports_handball; 
  }
}
.icon-sports_kabaddi {
  &:before {
    content: $icon-sports_kabaddi; 
  }
}
.icon-eco {
  &:before {
    content: $icon-eco; 
  }
}
.icon-museum {
  &:before {
    content: $icon-museum; 
  }
}
.icon-flip_camera_android {
  &:before {
    content: $icon-flip_camera_android; 
  }
}
.icon-flip_camera_ios {
  &:before {
    content: $icon-flip_camera_ios; 
  }
}
.icon-cancel_schedule_send {
  &:before {
    content: $icon-cancel_schedule_send; 
  }
}
.icon-apartment {
  &:before {
    content: $icon-apartment; 
  }
}
.icon-bathtub {
  &:before {
    content: $icon-bathtub; 
  }
}
.icon-deck {
  &:before {
    content: $icon-deck; 
  }
}
.icon-fireplace {
  &:before {
    content: $icon-fireplace; 
  }
}
.icon-house {
  &:before {
    content: $icon-house; 
  }
}
.icon-king_bed {
  &:before {
    content: $icon-king_bed; 
  }
}
.icon-nights_stay {
  &:before {
    content: $icon-nights_stay; 
  }
}
.icon-outdoor_grill {
  &:before {
    content: $icon-outdoor_grill; 
  }
}
.icon-single_bed {
  &:before {
    content: $icon-single_bed; 
  }
}
.icon-square_foot {
  &:before {
    content: $icon-square_foot; 
  }
}
.icon-double_arrow {
  &:before {
    content: $icon-double_arrow; 
  }
}
.icon-sports_baseball {
  &:before {
    content: $icon-sports_baseball; 
  }
}
.icon-attractions {
  &:before {
    content: $icon-attractions; 
  }
}
.icon-bakery_dining {
  &:before {
    content: $icon-bakery_dining; 
  }
}
.icon-breakfast_dining {
  &:before {
    content: $icon-breakfast_dining; 
  }
}
.icon-car_rental {
  &:before {
    content: $icon-car_rental; 
  }
}
.icon-car_repair {
  &:before {
    content: $icon-car_repair; 
  }
}
.icon-dinner_dining {
  &:before {
    content: $icon-dinner_dining; 
  }
}
.icon-dry_cleaning {
  &:before {
    content: $icon-dry_cleaning; 
  }
}
.icon-hardware {
  &:before {
    content: $icon-hardware; 
  }
}
.icon-liquor {
  &:before {
    content: $icon-liquor; 
  }
}
.icon-lunch_dining {
  &:before {
    content: $icon-lunch_dining; 
  }
}
.icon-nightlife {
  &:before {
    content: $icon-nightlife; 
  }
}
.icon-park {
  &:before {
    content: $icon-park; 
  }
}
.icon-ramen_dining {
  &:before {
    content: $icon-ramen_dining; 
  }
}
.icon-celebration {
  &:before {
    content: $icon-celebration; 
  }
}
.icon-theater_comedy {
  &:before {
    content: $icon-theater_comedy; 
  }
}
.icon-badge {
  &:before {
    content: $icon-badge; 
  }
}
.icon-festival {
  &:before {
    content: $icon-festival; 
  }
}
.icon-icecream {
  &:before {
    content: $icon-icecream; 
  }
}
.icon-volunteer_activism {
  &:before {
    content: $icon-volunteer_activism; 
  }
}
.icon-contactless {
  &:before {
    content: $icon-contactless; 
  }
}
.icon-delivery_dining {
  &:before {
    content: $icon-delivery_dining; 
  }
}
.icon-brunch_dining {
  &:before {
    content: $icon-brunch_dining; 
  }
}
.icon-takeout_dining {
  &:before {
    content: $icon-takeout_dining; 
  }
}
.icon-ac_unit {
  &:before {
    content: $icon-ac_unit; 
  }
}
.icon-airport_shuttle {
  &:before {
    content: $icon-airport_shuttle; 
  }
}
.icon-all_inclusive {
  &:before {
    content: $icon-all_inclusive; 
  }
}
.icon-beach_access {
  &:before {
    content: $icon-beach_access; 
  }
}
.icon-business_center {
  &:before {
    content: $icon-business_center; 
  }
}
.icon-casino {
  &:before {
    content: $icon-casino; 
  }
}
.icon-child_care {
  &:before {
    content: $icon-child_care; 
  }
}
.icon-child_friendly {
  &:before {
    content: $icon-child_friendly; 
  }
}
.icon-fitness_center {
  &:before {
    content: $icon-fitness_center; 
  }
}
.icon-golf_course {
  &:before {
    content: $icon-golf_course; 
  }
}
.icon-hot_tub {
  &:before {
    content: $icon-hot_tub; 
  }
}
.icon-kitchen {
  &:before {
    content: $icon-kitchen; 
  }
}
.icon-pool {
  &:before {
    content: $icon-pool; 
  }
}
.icon-room_service {
  &:before {
    content: $icon-room_service; 
  }
}
.icon-smoke_free {
  &:before {
    content: $icon-smoke_free; 
  }
}
.icon-smoking_rooms {
  &:before {
    content: $icon-smoking_rooms; 
  }
}
.icon-spa {
  &:before {
    content: $icon-spa; 
  }
}
.icon-no_meeting_room {
  &:before {
    content: $icon-no_meeting_room; 
  }
}
.icon-meeting_room {
  &:before {
    content: $icon-meeting_room; 
  }
}
.icon-goat {
  &:before {
    content: $icon-goat; 
  }
}
.icon-5g {
  &:before {
    content: $icon-5g; 
  }
}
.icon-ad_units {
  &:before {
    content: $icon-ad_units; 
  }
}
.icon-add_business {
  &:before {
    content: $icon-add_business; 
  }
}
.icon-add_location_alt {
  &:before {
    content: $icon-add_location_alt; 
  }
}
.icon-add_road {
  &:before {
    content: $icon-add_road; 
  }
}
.icon-add_to_drive {
  &:before {
    content: $icon-add_to_drive; 
  }
}
.icon-addchart {
  &:before {
    content: $icon-addchart; 
  }
}
.icon-admin_panel_settings {
  &:before {
    content: $icon-admin_panel_settings; 
  }
}
.icon-agriculture {
  &:before {
    content: $icon-agriculture; 
  }
}
.icon-alt_route {
  &:before {
    content: $icon-alt_route; 
  }
}
.icon-analytics {
  &:before {
    content: $icon-analytics; 
  }
}
.icon-anchor {
  &:before {
    content: $icon-anchor; 
  }
}
.icon-animation {
  &:before {
    content: $icon-animation; 
  }
}
.icon-api {
  &:before {
    content: $icon-api; 
  }
}
.icon-app_blocking {
  &:before {
    content: $icon-app_blocking; 
  }
}
.icon-app_registration {
  &:before {
    content: $icon-app_registration; 
  }
}
.icon-app_settings_alt {
  &:before {
    content: $icon-app_settings_alt; 
  }
}
.icon-architecture {
  &:before {
    content: $icon-architecture; 
  }
}
.icon-arrow_circle_down {
  &:before {
    content: $icon-arrow_circle_down; 
  }
}
.icon-arrow_circle_up {
  &:before {
    content: $icon-arrow_circle_up; 
  }
}
.icon-article {
  &:before {
    content: $icon-article; 
  }
}
.icon-attach_email {
  &:before {
    content: $icon-attach_email; 
  }
}
.icon-auto_awesome {
  &:before {
    content: $icon-auto_awesome; 
  }
}
.icon-auto_awesome_mosaic {
  &:before {
    content: $icon-auto_awesome_mosaic; 
  }
}
.icon-auto_awesome_motion {
  &:before {
    content: $icon-auto_awesome_motion; 
  }
}
.icon-auto_delete {
  &:before {
    content: $icon-auto_delete; 
  }
}
.icon-auto_fix_high {
  &:before {
    content: $icon-auto_fix_high; 
  }
}
.icon-auto_fix_normal {
  &:before {
    content: $icon-auto_fix_normal; 
  }
}
.icon-auto_fix_off {
  &:before {
    content: $icon-auto_fix_off; 
  }
}
.icon-auto_stories {
  &:before {
    content: $icon-auto_stories; 
  }
}
.icon-baby_changing_station {
  &:before {
    content: $icon-baby_changing_station; 
  }
}
.icon-backpack {
  &:before {
    content: $icon-backpack; 
  }
}
.icon-backup_table {
  &:before {
    content: $icon-backup_table; 
  }
}
.icon-batch_prediction {
  &:before {
    content: $icon-batch_prediction; 
  }
}
.icon-bedtime {
  &:before {
    content: $icon-bedtime; 
  }
}
.icon-bento {
  &:before {
    content: $icon-bento; 
  }
}
.icon-bike_scooter {
  &:before {
    content: $icon-bike_scooter; 
  }
}
.icon-biotech {
  &:before {
    content: $icon-biotech; 
  }
}
.icon-block_flipped {
  &:before {
    content: $icon-block_flipped; 
  }
}
.icon-browser_not_supported {
  &:before {
    content: $icon-browser_not_supported; 
  }
}
.icon-build_circle {
  &:before {
    content: $icon-build_circle; 
  }
}
.icon-calculate {
  &:before {
    content: $icon-calculate; 
  }
}
.icon-campaign {
  &:before {
    content: $icon-campaign; 
  }
}
.icon-carpenter {
  &:before {
    content: $icon-carpenter; 
  }
}
.icon-cast_for_education {
  &:before {
    content: $icon-cast_for_education; 
  }
}
.icon-charging_station {
  &:before {
    content: $icon-charging_station; 
  }
}
.icon-checkroom {
  &:before {
    content: $icon-checkroom; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-cleaning_services {
  &:before {
    content: $icon-cleaning_services; 
  }
}
.icon-close_fullscreen {
  &:before {
    content: $icon-close_fullscreen; 
  }
}
.icon-closed_caption_disabled {
  &:before {
    content: $icon-closed_caption_disabled; 
  }
}
.icon-comment_bank {
  &:before {
    content: $icon-comment_bank; 
  }
}
.icon-construction {
  &:before {
    content: $icon-construction; 
  }
}
.icon-corporate_fare {
  &:before {
    content: $icon-corporate_fare; 
  }
}
.icon-countertops {
  &:before {
    content: $icon-countertops; 
  }
}
.icon-design_services {
  &:before {
    content: $icon-design_services; 
  }
}
.icon-directions_off {
  &:before {
    content: $icon-directions_off; 
  }
}
.icon-dirty_lens {
  &:before {
    content: $icon-dirty_lens; 
  }
}
.icon-do_not_step {
  &:before {
    content: $icon-do_not_step; 
  }
}
.icon-do_not_touch {
  &:before {
    content: $icon-do_not_touch; 
  }
}
.icon-domain_verification {
  &:before {
    content: $icon-domain_verification; 
  }
}
.icon-drive_file_move {
  &:before {
    content: $icon-drive_file_move; 
  }
}
.icon-dry {
  &:before {
    content: $icon-dry; 
  }
}
.icon-dynamic_form {
  &:before {
    content: $icon-dynamic_form; 
  }
}
.icon-east {
  &:before {
    content: $icon-east; 
  }
}
.icon-edit_road {
  &:before {
    content: $icon-edit_road; 
  }
}
.icon-electric_bike {
  &:before {
    content: $icon-electric_bike; 
  }
}
.icon-electric_car {
  &:before {
    content: $icon-electric_car; 
  }
}
.icon-electric_moped {
  &:before {
    content: $icon-electric_moped; 
  }
}
.icon-electric_rickshaw {
  &:before {
    content: $icon-electric_rickshaw; 
  }
}
.icon-electric_scooter {
  &:before {
    content: $icon-electric_scooter; 
  }
}
.icon-electrical_services {
  &:before {
    content: $icon-electrical_services; 
  }
}
.icon-elevator {
  &:before {
    content: $icon-elevator; 
  }
}
.icon-engineering {
  &:before {
    content: $icon-engineering; 
  }
}
.icon-escalator {
  &:before {
    content: $icon-escalator; 
  }
}
.icon-escalator_warning {
  &:before {
    content: $icon-escalator_warning; 
  }
}
.icon-face_retouching_natural {
  &:before {
    content: $icon-face_retouching_natural; 
  }
}
.icon-fact_check {
  &:before {
    content: $icon-fact_check; 
  }
}
.icon-family_restroom {
  &:before {
    content: $icon-family_restroom; 
  }
}
.icon-fence {
  &:before {
    content: $icon-fence; 
  }
}
.icon-filter_alt {
  &:before {
    content: $icon-filter_alt; 
  }
}
.icon-fire_extinguisher {
  &:before {
    content: $icon-fire_extinguisher; 
  }
}
.icon-flaky {
  &:before {
    content: $icon-flaky; 
  }
}
.icon-food_bank {
  &:before {
    content: $icon-food_bank; 
  }
}
.icon-forward_to_inbox {
  &:before {
    content: $icon-forward_to_inbox; 
  }
}
.icon-foundation {
  &:before {
    content: $icon-foundation; 
  }
}
.icon-grading {
  &:before {
    content: $icon-grading; 
  }
}
.icon-grass {
  &:before {
    content: $icon-grass; 
  }
}
.icon-handyman {
  &:before {
    content: $icon-handyman; 
  }
}
.icon-hdr_enhanced_select {
  &:before {
    content: $icon-hdr_enhanced_select; 
  }
}
.icon-hearing_disabled {
  &:before {
    content: $icon-hearing_disabled; 
  }
}
.icon-help_center {
  &:before {
    content: $icon-help_center; 
  }
}
.icon-highlight_alt {
  &:before {
    content: $icon-highlight_alt; 
  }
}
.icon-history_edu {
  &:before {
    content: $icon-history_edu; 
  }
}
.icon-history_toggle_off {
  &:before {
    content: $icon-history_toggle_off; 
  }
}
.icon-home_repair_service {
  &:before {
    content: $icon-home_repair_service; 
  }
}
.icon-horizontal_rule {
  &:before {
    content: $icon-horizontal_rule; 
  }
}
.icon-hourglass_bottom {
  &:before {
    content: $icon-hourglass_bottom; 
  }
}
.icon-hourglass_disabled {
  &:before {
    content: $icon-hourglass_disabled; 
  }
}
.icon-hourglass_top {
  &:before {
    content: $icon-hourglass_top; 
  }
}
.icon-house_siding {
  &:before {
    content: $icon-house_siding; 
  }
}
.icon-hvac {
  &:before {
    content: $icon-hvac; 
  }
}
.icon-image_not_supported {
  &:before {
    content: $icon-image_not_supported; 
  }
}
.icon-insights {
  &:before {
    content: $icon-insights; 
  }
}
.icon-integration_instructions {
  &:before {
    content: $icon-integration_instructions; 
  }
}
.icon-ios_share {
  &:before {
    content: $icon-ios_share; 
  }
}
.icon-legend_toggle {
  &:before {
    content: $icon-legend_toggle; 
  }
}
.icon-local_fire_department {
  &:before {
    content: $icon-local_fire_department; 
  }
}
.icon-local_police {
  &:before {
    content: $icon-local_police; 
  }
}
.icon-location_pin {
  &:before {
    content: $icon-location_pin; 
  }
}
.icon-lock_clock {
  &:before {
    content: $icon-lock_clock; 
  }
}
.icon-login {
  &:before {
    content: $icon-login; 
  }
}
.icon-maps_ugc {
  &:before {
    content: $icon-maps_ugc; 
  }
}
.icon-mark_chat_read {
  &:before {
    content: $icon-mark_chat_read; 
  }
}
.icon-mark_chat_unread {
  &:before {
    content: $icon-mark_chat_unread; 
  }
}
.icon-mark_email_read {
  &:before {
    content: $icon-mark_email_read; 
  }
}
.icon-mark_email_unread {
  &:before {
    content: $icon-mark_email_unread; 
  }
}
.icon-mediation {
  &:before {
    content: $icon-mediation; 
  }
}
.icon-medical_services {
  &:before {
    content: $icon-medical_services; 
  }
}
.icon-mic_external_off {
  &:before {
    content: $icon-mic_external_off; 
  }
}
.icon-mic_external_on {
  &:before {
    content: $icon-mic_external_on; 
  }
}
.icon-microwave {
  &:before {
    content: $icon-microwave; 
  }
}
.icon-military_tech {
  &:before {
    content: $icon-military_tech; 
  }
}
.icon-miscellaneous_services {
  &:before {
    content: $icon-miscellaneous_services; 
  }
}
.icon-model_training {
  &:before {
    content: $icon-model_training; 
  }
}
.icon-monitor {
  &:before {
    content: $icon-monitor; 
  }
}
.icon-moped {
  &:before {
    content: $icon-moped; 
  }
}
.icon-more_time {
  &:before {
    content: $icon-more_time; 
  }
}
.icon-motion_photos_off {
  &:before {
    content: $icon-motion_photos_off; 
  }
}
.icon-motion_photos_on {
  &:before {
    content: $icon-motion_photos_on; 
  }
}
.icon-motion_photos_paused {
  &:before {
    content: $icon-motion_photos_paused; 
  }
}
.icon-multiple_stop {
  &:before {
    content: $icon-multiple_stop; 
  }
}
.icon-nat {
  &:before {
    content: $icon-nat; 
  }
}
.icon-near_me_disabled {
  &:before {
    content: $icon-near_me_disabled; 
  }
}
.icon-next_plan {
  &:before {
    content: $icon-next_plan; 
  }
}
.icon-night_shelter {
  &:before {
    content: $icon-night_shelter; 
  }
}
.icon-nightlight_round {
  &:before {
    content: $icon-nightlight_round; 
  }
}
.icon-no_cell {
  &:before {
    content: $icon-no_cell; 
  }
}
.icon-no_drinks {
  &:before {
    content: $icon-no_drinks; 
  }
}
.icon-no_flash {
  &:before {
    content: $icon-no_flash; 
  }
}
.icon-no_food {
  &:before {
    content: $icon-no_food; 
  }
}
.icon-no_meals {
  &:before {
    content: $icon-no_meals; 
  }
}
.icon-no_photography {
  &:before {
    content: $icon-no_photography; 
  }
}
.icon-no_stroller {
  &:before {
    content: $icon-no_stroller; 
  }
}
.icon-no_transfer {
  &:before {
    content: $icon-no_transfer; 
  }
}
.icon-north {
  &:before {
    content: $icon-north; 
  }
}
.icon-north_east {
  &:before {
    content: $icon-north_east; 
  }
}
.icon-north_west {
  &:before {
    content: $icon-north_west; 
  }
}
.icon-not_accessible {
  &:before {
    content: $icon-not_accessible; 
  }
}
.icon-not_started {
  &:before {
    content: $icon-not_started; 
  }
}
.icon-online_prediction {
  &:before {
    content: $icon-online_prediction; 
  }
}
.icon-open_in_full {
  &:before {
    content: $icon-open_in_full; 
  }
}
.icon-outbox {
  &:before {
    content: $icon-outbox; 
  }
}
.icon-outgoing_mail {
  &:before {
    content: $icon-outgoing_mail; 
  }
}
.icon-outlet {
  &:before {
    content: $icon-outlet; 
  }
}
.icon-panorama_horizontal_select {
  &:before {
    content: $icon-panorama_horizontal_select; 
  }
}
.icon-panorama_vertical_select {
  &:before {
    content: $icon-panorama_vertical_select; 
  }
}
.icon-panorama_wide_angle_select {
  &:before {
    content: $icon-panorama_wide_angle_select; 
  }
}
.icon-payments {
  &:before {
    content: $icon-payments; 
  }
}
.icon-pedal_bike {
  &:before {
    content: $icon-pedal_bike; 
  }
}
.icon-pending {
  &:before {
    content: $icon-pending; 
  }
}
.icon-pending_actions {
  &:before {
    content: $icon-pending_actions; 
  }
}
.icon-person_add_alt {
  &:before {
    content: $icon-person_add_alt; 
  }
}
.icon-person_add_alt_1 {
  &:before {
    content: $icon-person_add_alt_1; 
  }
}
.icon-person_remove {
  &:before {
    content: $icon-person_remove; 
  }
}
.icon-person_search {
  &:before {
    content: $icon-person_search; 
  }
}
.icon-pest_control {
  &:before {
    content: $icon-pest_control; 
  }
}
.icon-pest_control_rodent {
  &:before {
    content: $icon-pest_control_rodent; 
  }
}
.icon-photo_camera_back {
  &:before {
    content: $icon-photo_camera_back; 
  }
}
.icon-photo_camera_front {
  &:before {
    content: $icon-photo_camera_front; 
  }
}
.icon-plagiarism {
  &:before {
    content: $icon-plagiarism; 
  }
}
.icon-play_disabled {
  &:before {
    content: $icon-play_disabled; 
  }
}
.icon-plumbing {
  &:before {
    content: $icon-plumbing; 
  }
}
.icon-point_of_sale {
  &:before {
    content: $icon-point_of_sale; 
  }
}
.icon-preview {
  &:before {
    content: $icon-preview; 
  }
}
.icon-privacy_tip {
  &:before {
    content: $icon-privacy_tip; 
  }
}
.icon-psychology {
  &:before {
    content: $icon-psychology; 
  }
}
.icon-public_off {
  &:before {
    content: $icon-public_off; 
  }
}
.icon-push_pin {
  &:before {
    content: $icon-push_pin; 
  }
}
.icon-qr_code {
  &:before {
    content: $icon-qr_code; 
  }
}
.icon-qr_code_scanner {
  &:before {
    content: $icon-qr_code_scanner; 
  }
}
.icon-quickreply {
  &:before {
    content: $icon-quickreply; 
  }
}
.icon-read_more {
  &:before {
    content: $icon-read_more; 
  }
}
.icon-receipt_long {
  &:before {
    content: $icon-receipt_long; 
  }
}
.icon-request_quote {
  &:before {
    content: $icon-request_quote; 
  }
}
.icon-rice_bowl {
  &:before {
    content: $icon-rice_bowl; 
  }
}
.icon-roofing {
  &:before {
    content: $icon-roofing; 
  }
}
.icon-room_preferences {
  &:before {
    content: $icon-room_preferences; 
  }
}
.icon-rule {
  &:before {
    content: $icon-rule; 
  }
}
.icon-rule_folder {
  &:before {
    content: $icon-rule_folder; 
  }
}
.icon-run_circle {
  &:before {
    content: $icon-run_circle; 
  }
}
.icon-science {
  &:before {
    content: $icon-science; 
  }
}
.icon-screen_search_desktop {
  &:before {
    content: $icon-screen_search_desktop; 
  }
}
.icon-search_off {
  &:before {
    content: $icon-search_off; 
  }
}
.icon-self_improvement {
  &:before {
    content: $icon-self_improvement; 
  }
}
.icon-sensor_door {
  &:before {
    content: $icon-sensor_door; 
  }
}
.icon-sensor_window {
  &:before {
    content: $icon-sensor_window; 
  }
}
.icon-set_meal {
  &:before {
    content: $icon-set_meal; 
  }
}
.icon-shopping_bag {
  &:before {
    content: $icon-shopping_bag; 
  }
}
.icon-signal_cellular_0_bar {
  &:before {
    content: $icon-signal_cellular_0_bar; 
  }
}
.icon-signal_wifi_0_bar {
  &:before {
    content: $icon-signal_wifi_0_bar; 
  }
}
.icon-smart_button {
  &:before {
    content: $icon-smart_button; 
  }
}
.icon-snippet_folder {
  &:before {
    content: $icon-snippet_folder; 
  }
}
.icon-soap {
  &:before {
    content: $icon-soap; 
  }
}
.icon-source {
  &:before {
    content: $icon-source; 
  }
}
.icon-south {
  &:before {
    content: $icon-south; 
  }
}
.icon-south_east {
  &:before {
    content: $icon-south_east; 
  }
}
.icon-south_west {
  &:before {
    content: $icon-south_west; 
  }
}
.icon-sports_bar {
  &:before {
    content: $icon-sports_bar; 
  }
}
.icon-stairs {
  &:before {
    content: $icon-stairs; 
  }
}
.icon-star_outline1 {
  &:before {
    content: $icon-star_outline1; 
  }
}
.icon-star_rate {
  &:before {
    content: $icon-star_rate; 
  }
}
.icon-sticky_note_2 {
  &:before {
    content: $icon-sticky_note_2; 
  }
}
.icon-stop_circle {
  &:before {
    content: $icon-stop_circle; 
  }
}
.icon-stroller {
  &:before {
    content: $icon-stroller; 
  }
}
.icon-subscript {
  &:before {
    content: $icon-subscript; 
  }
}
.icon-subtitles_off {
  &:before {
    content: $icon-subtitles_off; 
  }
}
.icon-superscript {
  &:before {
    content: $icon-superscript; 
  }
}
.icon-support {
  &:before {
    content: $icon-support; 
  }
}
.icon-support_agent {
  &:before {
    content: $icon-support_agent; 
  }
}
.icon-switch_left {
  &:before {
    content: $icon-switch_left; 
  }
}
.icon-switch_right {
  &:before {
    content: $icon-switch_right; 
  }
}
.icon-table_rows {
  &:before {
    content: $icon-table_rows; 
  }
}
.icon-table_view {
  &:before {
    content: $icon-table_view; 
  }
}
.icon-tapas {
  &:before {
    content: $icon-tapas; 
  }
}
.icon-taxi_alert {
  &:before {
    content: $icon-taxi_alert; 
  }
}
.icon-text_snippet {
  &:before {
    content: $icon-text_snippet; 
  }
}
.icon-tour {
  &:before {
    content: $icon-tour; 
  }
}
.icon-tty {
  &:before {
    content: $icon-tty; 
  }
}
.icon-umbrella {
  &:before {
    content: $icon-umbrella; 
  }
}
.icon-upgrade {
  &:before {
    content: $icon-upgrade; 
  }
}
.icon-verified {
  &:before {
    content: $icon-verified; 
  }
}
.icon-video_settings {
  &:before {
    content: $icon-video_settings; 
  }
}
.icon-view_sidebar {
  &:before {
    content: $icon-view_sidebar; 
  }
}
.icon-wash {
  &:before {
    content: $icon-wash; 
  }
}
.icon-water_damage {
  &:before {
    content: $icon-water_damage; 
  }
}
.icon-west {
  &:before {
    content: $icon-west; 
  }
}
.icon-wheelchair_pickup {
  &:before {
    content: $icon-wheelchair_pickup; 
  }
}
.icon-wifi_calling {
  &:before {
    content: $icon-wifi_calling; 
  }
}
.icon-wifi_protected_setup {
  &:before {
    content: $icon-wifi_protected_setup; 
  }
}
.icon-wine_bar {
  &:before {
    content: $icon-wine_bar; 
  }
}
.icon-wrong_location {
  &:before {
    content: $icon-wrong_location; 
  }
}
.icon-wysiwyg {
  &:before {
    content: $icon-wysiwyg; 
  }
}
.icon-leaderboard {
  &:before {
    content: $icon-leaderboard; 
  }
}
.icon-6_ft_apart {
  &:before {
    content: $icon-6_ft_apart; 
  }
}
.icon-book_online {
  &:before {
    content: $icon-book_online; 
  }
}
.icon-clean_hands {
  &:before {
    content: $icon-clean_hands; 
  }
}
.icon-connect_without_contact {
  &:before {
    content: $icon-connect_without_contact; 
  }
}
.icon-coronavirus {
  &:before {
    content: $icon-coronavirus; 
  }
}
.icon-elderly {
  &:before {
    content: $icon-elderly; 
  }
}
.icon-follow_the_signs {
  &:before {
    content: $icon-follow_the_signs; 
  }
}
.icon-leave_bags_at_home {
  &:before {
    content: $icon-leave_bags_at_home; 
  }
}
.icon-masks {
  &:before {
    content: $icon-masks; 
  }
}
.icon-reduce_capacity {
  &:before {
    content: $icon-reduce_capacity; 
  }
}
.icon-sanitizer {
  &:before {
    content: $icon-sanitizer; 
  }
}
.icon-send_to_mobile {
  &:before {
    content: $icon-send_to_mobile; 
  }
}
.icon-sick {
  &:before {
    content: $icon-sick; 
  }
}
.icon-add_task {
  &:before {
    content: $icon-add_task; 
  }
}
.icon-contact_page {
  &:before {
    content: $icon-contact_page; 
  }
}
.icon-disabled_by_default {
  &:before {
    content: $icon-disabled_by_default; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-groups {
  &:before {
    content: $icon-groups; 
  }
}
.icon-luggage {
  &:before {
    content: $icon-luggage; 
  }
}
.icon-no_backpack {
  &:before {
    content: $icon-no_backpack; 
  }
}
.icon-no_luggage {
  &:before {
    content: $icon-no_luggage; 
  }
}
.icon-outbond {
  &:before {
    content: $icon-outbond; 
  }
}
.icon-published_with_changes {
  &:before {
    content: $icon-published_with_changes; 
  }
}
.icon-request_page {
  &:before {
    content: $icon-request_page; 
  }
}
.icon-stacked_line_chart {
  &:before {
    content: $icon-stacked_line_chart; 
  }
}
.icon-unpublished {
  &:before {
    content: $icon-unpublished; 
  }
}
.icon-align_horizontal_center {
  &:before {
    content: $icon-align_horizontal_center; 
  }
}
.icon-align_horizontal_left {
  &:before {
    content: $icon-align_horizontal_left; 
  }
}
.icon-align_horizontal_right {
  &:before {
    content: $icon-align_horizontal_right; 
  }
}
.icon-align_vertical_bottom {
  &:before {
    content: $icon-align_vertical_bottom; 
  }
}
.icon-align_vertical_center {
  &:before {
    content: $icon-align_vertical_center; 
  }
}
.icon-align_vertical_top {
  &:before {
    content: $icon-align_vertical_top; 
  }
}
.icon-horizontal_distribute {
  &:before {
    content: $icon-horizontal_distribute; 
  }
}
.icon-qr_code_2 {
  &:before {
    content: $icon-qr_code_2; 
  }
}
.icon-update_disabled {
  &:before {
    content: $icon-update_disabled; 
  }
}
.icon-vertical_distribute {
  &:before {
    content: $icon-vertical_distribute; 
  }
}

