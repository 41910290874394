@import "../../styles/colors.scss";

.header-wrapper {
  height: 60px;
  width: 100%;
  box-shadow: 0px -3px 7px 5px #afafaf;
  display: flex;
  position: fixed;
  top: 0;

  .menu-container {
    font-size: 2rem;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
    }
  }

  .menu_item {
    &:hover {
      transition: all 0.6s ease;
      opacity: 0.6;
      color: $light-blue;
      cursor: pointer;
    }
    &:not(:hover) {
      transition: all 0.6s ease;
      cursor: pointer;
    }
  }
}

.header-link {
  color: $font-color-dark;
  text-decoration: none;

  &:hover {
    color: $font-color-dark;
    font-weight: bold;
  }
  &:not(hover) {
    color: $font-color-dark;
    font-weight: normal;
  }
}

.fadeInHeader {
  animation: fadeIn 500ms ease-out forwards;
}
.fadeOutHeader {
  animation: fadeOut 500ms ease-out forwards;
}

@keyframes fadeIn {
  from {
    background-color: transparent;
    box-shadow: none;
  }
  to {
    background-color: #fff;
    box-shadow: 0px -3px 7px 5px #afafaf;
  }
}
@keyframes fadeOut {
  from {
    background-color: #fff;
    box-shadow: 0px -3px 7px 5px #afafaf;
  }
  to {
    background-color: transparent;
    box-shadow: none;
  }
}
