$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-error: "\e900";
$icon-error_outline: "\e901";
$icon-warning: "\e902";
$icon-add_alert: "\e903";
$icon-notification_important: "\e904";
$icon-album: "\e905";
$icon-av_timer: "\e906";
$icon-closed_caption: "\e907";
$icon-equalizer: "\e908";
$icon-explicit: "\e909";
$icon-fast_forward: "\e90a";
$icon-fast_rewind: "\e90b";
$icon-games: "\e90c";
$icon-hearing: "\e90d";
$icon-high_quality: "\e90e";
$icon-loop: "\e90f";
$icon-mic: "\e910";
$icon-mic_none: "\e911";
$icon-mic_off: "\e912";
$icon-movie: "\e913";
$icon-library_add: "\e914";
$icon-library_books: "\e915";
$icon-library_music: "\e916";
$icon-new_releases: "\e917";
$icon-not_interested: "\e918";
$icon-pause: "\e919";
$icon-pause_circle_filled: "\e91a";
$icon-pause_circle_outline: "\e91b";
$icon-play_arrow: "\e91c";
$icon-play_circle_filled: "\e91d";
$icon-play_circle_outline: "\e91e";
$icon-playlist_add: "\e91f";
$icon-queue_music: "\e920";
$icon-radio: "\e921";
$icon-recent_actors: "\e922";
$icon-repeat: "\e923";
$icon-repeat_one: "\e924";
$icon-replay: "\e925";
$icon-shuffle: "\e926";
$icon-skip_next: "\e927";
$icon-skip_previous: "\e928";
$icon-snooze: "\e929";
$icon-stop: "\e92a";
$icon-subtitles: "\e92b";
$icon-surround_sound: "\e92c";
$icon-video_library: "\e92d";
$icon-videocam: "\e92e";
$icon-videocam_off: "\e92f";
$icon-volume_down: "\e930";
$icon-volume_mute: "\e931";
$icon-volume_off: "\e932";
$icon-volume_up: "\e933";
$icon-web: "\e934";
$icon-hd: "\e935";
$icon-sort_by_alpha: "\e936";
$icon-airplay: "\e937";
$icon-forward_10: "\e938";
$icon-forward_30: "\e939";
$icon-forward_5: "\e93a";
$icon-replay_10: "\e93b";
$icon-replay_30: "\e93c";
$icon-replay_5: "\e93d";
$icon-add_to_queue: "\e93e";
$icon-fiber_dvr: "\e93f";
$icon-fiber_new: "\e940";
$icon-playlist_play: "\e941";
$icon-art_track: "\e942";
$icon-fiber_manual_record: "\e943";
$icon-fiber_smart_record: "\e944";
$icon-music_video: "\e945";
$icon-subscriptions: "\e946";
$icon-playlist_add_check: "\e947";
$icon-queue_play_next: "\e948";
$icon-remove_from_queue: "\e949";
$icon-slow_motion_video: "\e94a";
$icon-web_asset: "\e94b";
$icon-fiber_pin: "\e94c";
$icon-branding_watermark: "\e94d";
$icon-call_to_action: "\e94e";
$icon-featured_play_list: "\e94f";
$icon-featured_video: "\e950";
$icon-note: "\e951";
$icon-video_call: "\e952";
$icon-video_label: "\e953";
$icon-4k: "\e954";
$icon-missed_video_call: "\e955";
$icon-control_camera: "\e956";
$icon-business: "\e957";
$icon-call: "\e958";
$icon-call_end: "\e959";
$icon-call_made: "\e95a";
$icon-call_merge: "\e95b";
$icon-call_missed: "\e95c";
$icon-call_received: "\e95d";
$icon-call_split: "\e95e";
$icon-chat: "\e95f";
$icon-clear_all: "\e960";
$icon-comment: "\e961";
$icon-contacts: "\e962";
$icon-dialer_sip: "\e963";
$icon-dialpad: "\e964";
$icon-email: "\e965";
$icon-forum: "\e966";
$icon-import_export: "\e967";
$icon-invert_colors_off: "\e968";
$icon-live_help: "\e969";
$icon-location_off: "\e96a";
$icon-location_on: "\e96b";
$icon-message: "\e96c";
$icon-chat_bubble: "\e96d";
$icon-chat_bubble_outline: "\e96e";
$icon-no_sim: "\e96f";
$icon-phone: "\e970";
$icon-portable_wifi_off: "\e971";
$icon-contact_phone: "\e972";
$icon-contact_mail: "\e973";
$icon-ring_volume: "\e974";
$icon-speaker_phone: "\e975";
$icon-stay_current_landscape: "\e976";
$icon-stay_current_portrait: "\e977";
$icon-swap_calls: "\e978";
$icon-textsms: "\e979";
$icon-voicemail: "\e97a";
$icon-vpn_key: "\e97b";
$icon-phonelink_erase: "\e97c";
$icon-phonelink_lock: "\e97d";
$icon-phonelink_ring: "\e97e";
$icon-phonelink_setup: "\e97f";
$icon-present_to_all: "\e980";
$icon-import_contacts: "\e981";
$icon-mail_outline: "\e982";
$icon-screen_share: "\e983";
$icon-stop_screen_share: "\e984";
$icon-call_missed_outgoing: "\e985";
$icon-rss_feed: "\e986";
$icon-alternate_email: "\e987";
$icon-mobile_screen_share: "\e988";
$icon-add_call: "\e989";
$icon-cancel_presentation: "\e98a";
$icon-pause_presentation: "\e98b";
$icon-unsubscribe: "\e98c";
$icon-cell_wifi: "\e98d";
$icon-sentiment_satisfied_alt: "\e98e";
$icon-list_alt: "\e98f";
$icon-domain_disabled: "\e990";
$icon-lightbulb: "\e991";
$icon-add: "\e992";
$icon-add_box: "\e993";
$icon-add_circle: "\e994";
$icon-add_circle_outline: "\e995";
$icon-archive: "\e996";
$icon-backspace: "\e997";
$icon-block: "\e998";
$icon-clear: "\e999";
$icon-content_copy: "\e99a";
$icon-content_cut: "\e99b";
$icon-content_paste: "\e99c";
$icon-create: "\e99d";
$icon-drafts: "\e99e";
$icon-filter_list: "\e99f";
$icon-flag: "\e9a0";
$icon-forward: "\e9a1";
$icon-gesture: "\e9a2";
$icon-inbox: "\e9a3";
$icon-link: "\e9a4";
$icon-redo: "\e9a5";
$icon-remove: "\e9a6";
$icon-remove_circle: "\e9a7";
$icon-remove_circle_outline: "\e9a8";
$icon-reply: "\e9a9";
$icon-reply_all: "\e9aa";
$icon-report: "\e9ab";
$icon-save: "\e9ac";
$icon-select_all: "\e9ad";
$icon-send: "\e9ae";
$icon-sort: "\e9af";
$icon-text_format: "\e9b0";
$icon-undo: "\e9b1";
$icon-font_download: "\e9b2";
$icon-move_to_inbox: "\e9b3";
$icon-unarchive: "\e9b4";
$icon-next_week: "\e9b5";
$icon-weekend: "\e9b6";
$icon-delete_sweep: "\e9b7";
$icon-low_priority: "\e9b8";
$icon-outlined_flag: "\e9b9";
$icon-link_off: "\e9ba";
$icon-report_off: "\e9bb";
$icon-save_alt: "\e9bc";
$icon-ballot: "\e9bd";
$icon-file_copy: "\e9be";
$icon-how_to_reg: "\e9bf";
$icon-how_to_vote: "\e9c0";
$icon-waves: "\e9c1";
$icon-where_to_vote: "\e9c2";
$icon-add_link: "\e9c3";
$icon-inventory: "\e9c4";
$icon-access_alarm: "\e9c5";
$icon-access_alarms: "\e9c6";
$icon-access_time: "\e9c7";
$icon-add_alarm: "\e9c8";
$icon-airplanemode_off: "\e9c9";
$icon-airplanemode_on: "\e9ca";
$icon-battery_alert: "\e9cb";
$icon-battery_charging_full: "\e9cc";
$icon-battery_full: "\e9cd";
$icon-battery_unknown: "\e9ce";
$icon-bluetooth: "\e9cf";
$icon-bluetooth_connected: "\e9d0";
$icon-bluetooth_disabled: "\e9d1";
$icon-bluetooth_searching: "\e9d2";
$icon-brightness_auto: "\e9d3";
$icon-brightness_high: "\e9d4";
$icon-brightness_low: "\e9d5";
$icon-brightness_medium: "\e9d6";
$icon-data_usage: "\e9d7";
$icon-developer_mode: "\e9d8";
$icon-devices: "\e9d9";
$icon-dvr: "\e9da";
$icon-gps_fixed: "\e9db";
$icon-gps_not_fixed: "\e9dc";
$icon-gps_off: "\e9dd";
$icon-graphic_eq: "\e9de";
$icon-network_cell: "\e9df";
$icon-network_wifi: "\e9e0";
$icon-nfc: "\e9e1";
$icon-now_wallpaper: "\e9e2";
$icon-now_widgets: "\e9e3";
$icon-screen_lock_landscape: "\e9e4";
$icon-screen_lock_portrait: "\e9e5";
$icon-screen_lock_rotation: "\e9e6";
$icon-screen_rotation: "\e9e7";
$icon-sd_storage: "\e9e8";
$icon-settings_system_daydream: "\e9e9";
$icon-signal_cellular_4_bar: "\e9ea";
$icon-signal_cellular_connected_no_internet_4_bar: "\e9eb";
$icon-signal_cellular_null: "\e9ec";
$icon-signal_cellular_off: "\e9ed";
$icon-signal_wifi_4_bar: "\e9ee";
$icon-signal_wifi_4_bar_lock: "\e9ef";
$icon-signal_wifi_off: "\e9f0";
$icon-storage: "\e9f1";
$icon-usb: "\e9f2";
$icon-wifi_lock: "\e9f3";
$icon-wifi_tethering: "\e9f4";
$icon-add_to_home_screen: "\e9f5";
$icon-device_thermostat: "\e9f6";
$icon-mobile_friendly: "\e9f7";
$icon-mobile_off: "\e9f8";
$icon-signal_cellular_alt: "\e9f9";
$icon-attach_file: "\e9fa";
$icon-attach_money: "\e9fb";
$icon-border_all: "\e9fc";
$icon-border_bottom: "\e9fd";
$icon-border_clear: "\e9fe";
$icon-border_color: "\e9ff";
$icon-border_horizontal: "\ea00";
$icon-border_inner: "\ea01";
$icon-border_left: "\ea02";
$icon-border_outer: "\ea03";
$icon-border_right: "\ea04";
$icon-border_style: "\ea05";
$icon-border_top: "\ea06";
$icon-border_vertical: "\ea07";
$icon-format_align_center: "\ea08";
$icon-format_align_justify: "\ea09";
$icon-format_align_left: "\ea0a";
$icon-format_align_right: "\ea0b";
$icon-format_bold: "\ea0c";
$icon-format_clear: "\ea0d";
$icon-format_color_fill: "\ea0e";
$icon-format_color_reset: "\ea0f";
$icon-format_color_text: "\ea10";
$icon-format_indent_decrease: "\ea11";
$icon-format_indent_increase: "\ea12";
$icon-format_italic: "\ea13";
$icon-format_line_spacing: "\ea14";
$icon-format_list_bulleted: "\ea15";
$icon-format_list_numbered: "\ea16";
$icon-format_paint: "\ea17";
$icon-format_quote: "\ea18";
$icon-format_size: "\ea19";
$icon-format_strikethrough: "\ea1a";
$icon-format_textdirection_l_to_r: "\ea1b";
$icon-format_textdirection_r_to_l: "\ea1c";
$icon-format_underlined: "\ea1d";
$icon-functions: "\ea1e";
$icon-insert_chart: "\ea1f";
$icon-insert_comment: "\ea20";
$icon-insert_drive_file: "\ea21";
$icon-insert_emoticon: "\ea22";
$icon-insert_invitation: "\ea23";
$icon-insert_photo: "\ea24";
$icon-mode_comment: "\ea25";
$icon-publish: "\ea26";
$icon-space_bar: "\ea27";
$icon-strikethrough_s: "\ea28";
$icon-vertical_align_bottom: "\ea29";
$icon-vertical_align_center: "\ea2a";
$icon-vertical_align_top: "\ea2b";
$icon-wrap_text: "\ea2c";
$icon-money_off: "\ea2d";
$icon-drag_handle: "\ea2e";
$icon-format_shapes: "\ea2f";
$icon-highlight: "\ea30";
$icon-linear_scale: "\ea31";
$icon-short_text: "\ea32";
$icon-text_fields: "\ea33";
$icon-monetization_on: "\ea34";
$icon-title: "\ea35";
$icon-table_chart: "\ea36";
$icon-add_comment: "\ea37";
$icon-format_list_numbered_rtl: "\ea38";
$icon-scatter_plot: "\ea39";
$icon-score: "\ea3a";
$icon-insert_chart_outlined: "\ea3b";
$icon-bar_chart: "\ea3c";
$icon-notes: "\ea3d";
$icon-attachment: "\ea3e";
$icon-cloud: "\ea3f";
$icon-cloud_circle: "\ea40";
$icon-cloud_done: "\ea41";
$icon-cloud_download: "\ea42";
$icon-cloud_off: "\ea43";
$icon-cloud_queue: "\ea44";
$icon-cloud_upload: "\ea45";
$icon-file_download: "\ea46";
$icon-file_upload: "\ea47";
$icon-folder: "\ea48";
$icon-folder_open: "\ea49";
$icon-folder_shared: "\ea4a";
$icon-create_new_folder: "\ea4b";
$icon-cast: "\ea4c";
$icon-cast_connected: "\ea4d";
$icon-computer: "\ea4e";
$icon-desktop_mac: "\ea4f";
$icon-desktop_windows: "\ea50";
$icon-developer_board: "\ea51";
$icon-dock: "\ea52";
$icon-headset: "\ea53";
$icon-headset_mic: "\ea54";
$icon-keyboard: "\ea55";
$icon-keyboard_arrow_down: "\ea56";
$icon-keyboard_arrow_left: "\ea57";
$icon-keyboard_arrow_right: "\ea58";
$icon-keyboard_arrow_up: "\ea59";
$icon-keyboard_backspace: "\ea5a";
$icon-keyboard_capslock: "\ea5b";
$icon-keyboard_hide: "\ea5c";
$icon-keyboard_return: "\ea5d";
$icon-keyboard_tab: "\ea5e";
$icon-keyboard_voice: "\ea5f";
$icon-laptop_chromebook: "\ea60";
$icon-laptop_mac: "\ea61";
$icon-laptop_windows: "\ea62";
$icon-memory: "\ea63";
$icon-mouse: "\ea64";
$icon-phone_android: "\ea65";
$icon-phone_iphone: "\ea66";
$icon-phonelink_off: "\ea67";
$icon-router: "\ea68";
$icon-scanner: "\ea69";
$icon-security: "\ea6a";
$icon-sim_card: "\ea6b";
$icon-speaker: "\ea6c";
$icon-speaker_group: "\ea6d";
$icon-tablet: "\ea6e";
$icon-tablet_android: "\ea6f";
$icon-tablet_mac: "\ea70";
$icon-toys: "\ea71";
$icon-tv: "\ea72";
$icon-watch: "\ea73";
$icon-device_hub: "\ea74";
$icon-power_input: "\ea75";
$icon-devices_other: "\ea76";
$icon-videogame_asset: "\ea77";
$icon-device_unknown: "\ea78";
$icon-headset_off: "\ea79";
$icon-adjust: "\ea7a";
$icon-assistant: "\ea7b";
$icon-audiotrack: "\ea7c";
$icon-blur_circular: "\ea7d";
$icon-blur_linear: "\ea7e";
$icon-blur_off: "\ea7f";
$icon-blur_on: "\ea80";
$icon-brightness_1: "\ea81";
$icon-brightness_2: "\ea82";
$icon-brightness_3: "\ea83";
$icon-brightness_4: "\ea84";
$icon-broken_image: "\ea85";
$icon-brush: "\ea86";
$icon-camera: "\ea87";
$icon-camera_alt: "\ea88";
$icon-camera_front: "\ea89";
$icon-camera_rear: "\ea8a";
$icon-camera_roll: "\ea8b";
$icon-center_focus_strong: "\ea8c";
$icon-center_focus_weak: "\ea8d";
$icon-collections: "\ea8e";
$icon-color_lens: "\ea8f";
$icon-colorize: "\ea90";
$icon-compare: "\ea91";
$icon-control_point_duplicate: "\ea92";
$icon-crop_16_9: "\ea93";
$icon-crop_3_2: "\ea94";
$icon-crop: "\ea95";
$icon-crop_5_4: "\ea96";
$icon-crop_7_5: "\ea97";
$icon-crop_din: "\ea98";
$icon-crop_free: "\ea99";
$icon-crop_original: "\ea9a";
$icon-crop_portrait: "\ea9b";
$icon-crop_square: "\ea9c";
$icon-dehaze: "\ea9d";
$icon-details: "\ea9e";
$icon-exposure: "\ea9f";
$icon-exposure_neg_1: "\eaa0";
$icon-exposure_neg_2: "\eaa1";
$icon-exposure_plus_1: "\eaa2";
$icon-exposure_plus_2: "\eaa3";
$icon-exposure_zero: "\eaa4";
$icon-filter_1: "\eaa5";
$icon-filter_2: "\eaa6";
$icon-filter_3: "\eaa7";
$icon-filter: "\eaa8";
$icon-filter_4: "\eaa9";
$icon-filter_5: "\eaaa";
$icon-filter_6: "\eaab";
$icon-filter_7: "\eaac";
$icon-filter_8: "\eaad";
$icon-filter_9: "\eaae";
$icon-filter_9_plus: "\eaaf";
$icon-filter_b_and_w: "\eab0";
$icon-filter_center_focus: "\eab1";
$icon-filter_drama: "\eab2";
$icon-filter_frames: "\eab3";
$icon-filter_hdr: "\eab4";
$icon-filter_none: "\eab5";
$icon-filter_tilt_shift: "\eab6";
$icon-filter_vintage: "\eab7";
$icon-flare: "\eab8";
$icon-flash_auto: "\eab9";
$icon-flash_off: "\eaba";
$icon-flash_on: "\eabb";
$icon-flip: "\eabc";
$icon-gradient: "\eabd";
$icon-grain: "\eabe";
$icon-grid_off: "\eabf";
$icon-grid_on: "\eac0";
$icon-hdr_off: "\eac1";
$icon-hdr_on: "\eac2";
$icon-hdr_strong: "\eac3";
$icon-hdr_weak: "\eac4";
$icon-healing: "\eac5";
$icon-image_aspect_ratio: "\eac6";
$icon-iso: "\eac7";
$icon-leak_add: "\eac8";
$icon-leak_remove: "\eac9";
$icon-lens: "\eaca";
$icon-looks_3: "\eacb";
$icon-looks: "\eacc";
$icon-looks_4: "\eacd";
$icon-looks_5: "\eace";
$icon-looks_6: "\eacf";
$icon-looks_one: "\ead0";
$icon-looks_two: "\ead1";
$icon-loupe: "\ead2";
$icon-monochrome_photos: "\ead3";
$icon-music_note: "\ead4";
$icon-nature: "\ead5";
$icon-nature_people: "\ead6";
$icon-navigate_before: "\ead7";
$icon-navigate_next: "\ead8";
$icon-panorama: "\ead9";
$icon-panorama_fisheye: "\eada";
$icon-panorama_horizontal: "\eadb";
$icon-panorama_vertical: "\eadc";
$icon-panorama_wide_angle: "\eadd";
$icon-photo_album: "\eade";
$icon-picture_as_pdf: "\eadf";
$icon-portrait: "\eae0";
$icon-remove_red_eye: "\eae1";
$icon-rotate_90_degrees_ccw: "\eae2";
$icon-rotate_left: "\eae3";
$icon-rotate_right: "\eae4";
$icon-slideshow: "\eae5";
$icon-straighten: "\eae6";
$icon-style: "\eae7";
$icon-switch_camera: "\eae8";
$icon-switch_video: "\eae9";
$icon-texture: "\eaea";
$icon-timelapse: "\eaeb";
$icon-timer_10: "\eaec";
$icon-timer_3: "\eaed";
$icon-timer: "\eaee";
$icon-timer_off: "\eaef";
$icon-tonality: "\eaf0";
$icon-transform: "\eaf1";
$icon-tune: "\eaf2";
$icon-view_comfy: "\eaf3";
$icon-view_compact: "\eaf4";
$icon-wb_auto: "\eaf5";
$icon-wb_cloudy: "\eaf6";
$icon-wb_incandescent: "\eaf7";
$icon-wb_sunny: "\eaf8";
$icon-collections_bookmark: "\eaf9";
$icon-photo_size_select_actual: "\eafa";
$icon-photo_size_select_large: "\eafb";
$icon-photo_size_select_small: "\eafc";
$icon-vignette: "\eafd";
$icon-wb_iridescent: "\eafe";
$icon-crop_rotate: "\eaff";
$icon-linked_camera: "\eb00";
$icon-add_a_photo: "\eb01";
$icon-movie_filter: "\eb02";
$icon-photo_filter: "\eb03";
$icon-burst_mode: "\eb04";
$icon-shutter_speed: "\eb05";
$icon-add_photo_alternate: "\eb06";
$icon-image_search: "\eb07";
$icon-music_off: "\eb08";
$icon-beenhere: "\eb09";
$icon-directions: "\eb0a";
$icon-directions_bike: "\eb0b";
$icon-directions_bus: "\eb0c";
$icon-directions_car: "\eb0d";
$icon-directions_ferry: "\eb0e";
$icon-directions_subway: "\eb0f";
$icon-directions_train: "\eb10";
$icon-directions_walk: "\eb11";
$icon-hotel: "\eb12";
$icon-layers: "\eb13";
$icon-layers_clear: "\eb14";
$icon-local_atm: "\eb15";
$icon-local_attraction: "\eb16";
$icon-local_bar: "\eb17";
$icon-local_cafe: "\eb18";
$icon-local_car_wash: "\eb19";
$icon-local_convenience_store: "\eb1a";
$icon-local_drink: "\eb1b";
$icon-local_florist: "\eb1c";
$icon-local_gas_station: "\eb1d";
$icon-local_grocery_store: "\eb1e";
$icon-local_hospital: "\eb1f";
$icon-local_laundry_service: "\eb20";
$icon-local_library: "\eb21";
$icon-local_mall: "\eb22";
$icon-local_movies: "\eb23";
$icon-local_offer: "\eb24";
$icon-local_parking: "\eb25";
$icon-local_pharmacy: "\eb26";
$icon-local_pizza: "\eb27";
$icon-local_printshop: "\eb28";
$icon-local_restaurant: "\eb29";
$icon-local_shipping: "\eb2a";
$icon-local_taxi: "\eb2b";
$icon-location_history: "\eb2c";
$icon-map: "\eb2d";
$icon-navigation: "\eb2e";
$icon-pin_drop: "\eb2f";
$icon-rate_review: "\eb30";
$icon-satellite: "\eb31";
$icon-store_mall_directory: "\eb32";
$icon-traffic: "\eb33";
$icon-directions_run: "\eb34";
$icon-add_location: "\eb35";
$icon-edit_location: "\eb36";
$icon-near_me: "\eb37";
$icon-person_pin_circle: "\eb38";
$icon-zoom_out_map: "\eb39";
$icon-restaurant: "\eb3a";
$icon-ev_station: "\eb3b";
$icon-streetview: "\eb3c";
$icon-subway: "\eb3d";
$icon-train: "\eb3e";
$icon-tram: "\eb3f";
$icon-transfer_within_a_station: "\eb40";
$icon-atm: "\eb41";
$icon-category: "\eb42";
$icon-not_listed_location: "\eb43";
$icon-departure_board: "\eb44";
$icon-360: "\eb45";
$icon-edit_attributes: "\eb46";
$icon-transit_enterexit: "\eb47";
$icon-fastfood: "\eb48";
$icon-trip_origin: "\eb49";
$icon-compass_calibration: "\eb4a";
$icon-money: "\eb4b";
$icon-apps: "\eb4c";
$icon-arrow_back: "\eb4d";
$icon-arrow_drop_down: "\eb4e";
$icon-arrow_drop_down_circle: "\eb4f";
$icon-arrow_drop_up: "\eb50";
$icon-arrow_forward: "\eb51";
$icon-cancel: "\eb52";
$icon-check: "\eb53";
$icon-expand_less: "\eb54";
$icon-expand_more: "\eb55";
$icon-fullscreen: "\eb56";
$icon-fullscreen_exit: "\eb57";
$icon-menu: "\eb58";
$icon-keyboard_control: "\eb59";
$icon-more_vert: "\eb5a";
$icon-refresh: "\eb5b";
$icon-unfold_less: "\eb5c";
$icon-unfold_more: "\eb5d";
$icon-arrow_upward: "\eb5e";
$icon-subdirectory_arrow_left: "\eb5f";
$icon-subdirectory_arrow_right: "\eb60";
$icon-arrow_downward: "\eb61";
$icon-first_page: "\eb62";
$icon-last_page: "\eb63";
$icon-arrow_left: "\eb64";
$icon-arrow_right: "\eb65";
$icon-arrow_back_ios: "\eb66";
$icon-arrow_forward_ios: "\eb67";
$icon-adb: "\eb68";
$icon-disc_full: "\eb69";
$icon-do_not_disturb_alt: "\eb6a";
$icon-drive_eta: "\eb6b";
$icon-event_available: "\eb6c";
$icon-event_busy: "\eb6d";
$icon-event_note: "\eb6e";
$icon-folder_special: "\eb6f";
$icon-mms: "\eb70";
$icon-more: "\eb71";
$icon-network_locked: "\eb72";
$icon-phone_bluetooth_speaker: "\eb73";
$icon-phone_forwarded: "\eb74";
$icon-phone_in_talk: "\eb75";
$icon-phone_locked: "\eb76";
$icon-phone_missed: "\eb77";
$icon-phone_paused: "\eb78";
$icon-sim_card_alert: "\eb79";
$icon-sms_failed: "\eb7a";
$icon-sync_disabled: "\eb7b";
$icon-sync_problem: "\eb7c";
$icon-system_update: "\eb7d";
$icon-tap_and_play: "\eb7e";
$icon-vibration: "\eb7f";
$icon-voice_chat: "\eb80";
$icon-vpn_lock: "\eb81";
$icon-airline_seat_flat: "\eb82";
$icon-airline_seat_flat_angled: "\eb83";
$icon-airline_seat_individual_suite: "\eb84";
$icon-airline_seat_legroom_extra: "\eb85";
$icon-airline_seat_legroom_normal: "\eb86";
$icon-airline_seat_legroom_reduced: "\eb87";
$icon-airline_seat_recline_extra: "\eb88";
$icon-airline_seat_recline_normal: "\eb89";
$icon-confirmation_number: "\eb8a";
$icon-live_tv: "\eb8b";
$icon-ondemand_video: "\eb8c";
$icon-personal_video: "\eb8d";
$icon-power: "\eb8e";
$icon-wc: "\eb8f";
$icon-wifi: "\eb90";
$icon-enhanced_encryption: "\eb91";
$icon-network_check: "\eb92";
$icon-no_encryption: "\eb93";
$icon-rv_hookup: "\eb94";
$icon-do_not_disturb_off: "\eb95";
$icon-priority_high: "\eb96";
$icon-power_off: "\eb97";
$icon-tv_off: "\eb98";
$icon-wifi_off: "\eb99";
$icon-phone_callback: "\eb9a";
$icon-pie_chart: "\eb9b";
$icon-pie_chart_outlined: "\eb9c";
$icon-bubble_chart: "\eb9d";
$icon-multiline_chart: "\eb9e";
$icon-show_chart: "\eb9f";
$icon-cake: "\eba0";
$icon-group: "\eba1";
$icon-group_add: "\eba2";
$icon-location_city: "\eba3";
$icon-mood_bad: "\eba4";
$icon-notifications: "\eba5";
$icon-notifications_none: "\eba6";
$icon-notifications_off: "\eba7";
$icon-notifications_on: "\eba8";
$icon-notifications_paused: "\eba9";
$icon-pages: "\ebaa";
$icon-party_mode: "\ebab";
$icon-people_outline: "\ebac";
$icon-person: "\ebad";
$icon-person_add: "\ebae";
$icon-person_outline: "\ebaf";
$icon-plus_one: "\ebb0";
$icon-public: "\ebb1";
$icon-school: "\ebb2";
$icon-share: "\ebb3";
$icon-whatshot: "\ebb4";
$icon-sentiment_dissatisfied: "\ebb5";
$icon-sentiment_neutral: "\ebb6";
$icon-sentiment_satisfied: "\ebb7";
$icon-sentiment_very_dissatisfied: "\ebb8";
$icon-sentiment_very_satisfied: "\ebb9";
$icon-thumb_down_alt: "\ebba";
$icon-thumb_up_alt: "\ebbb";
$icon-check_box: "\ebbc";
$icon-check_box_outline_blank: "\ebbd";
$icon-radio_button_on: "\ebbe";
$icon-star: "\ebbf";
$icon-star_half: "\ebc0";
$icon-star_outline: "\ebc1";
$icon-3d_rotation: "\ebc2";
$icon-accessibility: "\ebc3";
$icon-account_balance: "\ebc4";
$icon-account_balance_wallet: "\ebc5";
$icon-account_box: "\ebc6";
$icon-account_circle: "\ebc7";
$icon-add_shopping_cart: "\ebc8";
$icon-alarm_off: "\ebc9";
$icon-alarm_on: "\ebca";
$icon-android: "\ebcb";
$icon-announcement: "\ebcc";
$icon-aspect_ratio: "\ebcd";
$icon-assignment: "\ebce";
$icon-assignment_ind: "\ebcf";
$icon-assignment_late: "\ebd0";
$icon-assignment_return: "\ebd1";
$icon-assignment_returned: "\ebd2";
$icon-assignment_turned_in: "\ebd3";
$icon-autorenew: "\ebd4";
$icon-book: "\ebd5";
$icon-bookmark: "\ebd6";
$icon-bookmark_outline: "\ebd7";
$icon-bug_report: "\ebd8";
$icon-build: "\ebd9";
$icon-cached: "\ebda";
$icon-change_history: "\ebdb";
$icon-check_circle: "\ebdc";
$icon-chrome_reader_mode: "\ebdd";
$icon-code: "\ebde";
$icon-credit_card: "\ebdf";
$icon-dashboard: "\ebe0";
$icon-delete: "\ebe1";
$icon-description: "\ebe2";
$icon-dns: "\ebe3";
$icon-done: "\ebe4";
$icon-done_all: "\ebe5";
$icon-exit_to_app: "\ebe6";
$icon-explore: "\ebe7";
$icon-extension: "\ebe8";
$icon-face: "\ebe9";
$icon-favorite: "\ebea";
$icon-favorite_outline: "\ebeb";
$icon-find_in_page: "\ebec";
$icon-find_replace: "\ebed";
$icon-flip_to_back: "\ebee";
$icon-flip_to_front: "\ebef";
$icon-group_work: "\ebf0";
$icon-help: "\ebf1";
$icon-highlight_remove: "\ebf2";
$icon-history: "\ebf3";
$icon-home: "\ebf4";
$icon-hourglass_empty: "\ebf5";
$icon-hourglass_full: "\ebf6";
$icon-https: "\ebf7";
$icon-info: "\ebf8";
$icon-info_outline: "\ebf9";
$icon-input: "\ebfa";
$icon-invert_colors_on: "\ebfb";
$icon-label: "\ebfc";
$icon-label_outline: "\ebfd";
$icon-language: "\ebfe";
$icon-launch: "\ebff";
$icon-list: "\ec00";
$icon-lock_open: "\ec01";
$icon-lock_outline: "\ec02";
$icon-loyalty: "\ec03";
$icon-markunread_mailbox: "\ec04";
$icon-note_add: "\ec05";
$icon-open_in_browser: "\ec06";
$icon-open_with: "\ec07";
$icon-pageview: "\ec08";
$icon-perm_camera_mic: "\ec09";
$icon-perm_contact_calendar: "\ec0a";
$icon-perm_data_setting: "\ec0b";
$icon-perm_device_information: "\ec0c";
$icon-perm_media: "\ec0d";
$icon-perm_phone_msg: "\ec0e";
$icon-perm_scan_wifi: "\ec0f";
$icon-picture_in_picture: "\ec10";
$icon-polymer: "\ec11";
$icon-power_settings_new: "\ec12";
$icon-receipt: "\ec13";
$icon-redeem: "\ec14";
$icon-search: "\ec15";
$icon-settings: "\ec16";
$icon-settings_applications: "\ec17";
$icon-settings_backup_restore: "\ec18";
$icon-settings_bluetooth: "\ec19";
$icon-settings_cell: "\ec1a";
$icon-settings_display: "\ec1b";
$icon-settings_ethernet: "\ec1c";
$icon-settings_input_antenna: "\ec1d";
$icon-settings_input_component: "\ec1e";
$icon-settings_input_hdmi: "\ec1f";
$icon-settings_input_svideo: "\ec20";
$icon-settings_overscan: "\ec21";
$icon-settings_phone: "\ec22";
$icon-settings_power: "\ec23";
$icon-settings_remote: "\ec24";
$icon-settings_voice: "\ec25";
$icon-shop: "\ec26";
$icon-shop_two: "\ec27";
$icon-shopping_basket: "\ec28";
$icon-speaker_notes: "\ec29";
$icon-spellcheck: "\ec2a";
$icon-stars: "\ec2b";
$icon-subject: "\ec2c";
$icon-supervisor_account: "\ec2d";
$icon-swap_horiz: "\ec2e";
$icon-swap_vert: "\ec2f";
$icon-swap_vertical_circle: "\ec30";
$icon-system_update_tv: "\ec31";
$icon-tab: "\ec32";
$icon-tab_unselected: "\ec33";
$icon-thumb_down: "\ec34";
$icon-thumb_up: "\ec35";
$icon-thumbs_up_down: "\ec36";
$icon-toc: "\ec37";
$icon-today: "\ec38";
$icon-toll: "\ec39";
$icon-track_changes: "\ec3a";
$icon-translate: "\ec3b";
$icon-trending_down: "\ec3c";
$icon-trending_neutral: "\ec3d";
$icon-trending_up: "\ec3e";
$icon-verified_user: "\ec3f";
$icon-view_agenda: "\ec40";
$icon-view_array: "\ec41";
$icon-view_carousel: "\ec42";
$icon-view_column: "\ec43";
$icon-view_day: "\ec44";
$icon-view_headline: "\ec45";
$icon-view_list: "\ec46";
$icon-view_module: "\ec47";
$icon-view_quilt: "\ec48";
$icon-view_stream: "\ec49";
$icon-view_week: "\ec4a";
$icon-visibility_off: "\ec4b";
$icon-card_membership: "\ec4c";
$icon-card_travel: "\ec4d";
$icon-work: "\ec4e";
$icon-youtube_searched_for: "\ec4f";
$icon-eject: "\ec50";
$icon-camera_enhance: "\ec51";
$icon-help_outline: "\ec52";
$icon-reorder: "\ec53";
$icon-zoom_in: "\ec54";
$icon-zoom_out: "\ec55";
$icon-http: "\ec56";
$icon-event_seat: "\ec57";
$icon-flight_land: "\ec58";
$icon-flight_takeoff: "\ec59";
$icon-play_for_work: "\ec5a";
$icon-gif: "\ec5b";
$icon-indeterminate_check_box: "\ec5c";
$icon-offline_pin: "\ec5d";
$icon-all_out: "\ec5e";
$icon-copyright: "\ec5f";
$icon-fingerprint: "\ec60";
$icon-gavel: "\ec61";
$icon-lightbulb_outline: "\ec62";
$icon-picture_in_picture_alt: "\ec63";
$icon-important_devices: "\ec64";
$icon-touch_app: "\ec65";
$icon-accessible: "\ec66";
$icon-compare_arrows: "\ec67";
$icon-date_range: "\ec68";
$icon-donut_large: "\ec69";
$icon-donut_small: "\ec6a";
$icon-line_style: "\ec6b";
$icon-line_weight: "\ec6c";
$icon-motorcycle: "\ec6d";
$icon-opacity: "\ec6e";
$icon-pets: "\ec6f";
$icon-pregnant_woman: "\ec70";
$icon-record_voice_over: "\ec71";
$icon-rounded_corner: "\ec72";
$icon-rowing: "\ec73";
$icon-timeline: "\ec74";
$icon-update: "\ec75";
$icon-watch_later: "\ec76";
$icon-pan_tool: "\ec77";
$icon-euro_symbol: "\ec78";
$icon-g_translate: "\ec79";
$icon-remove_shopping_cart: "\ec7a";
$icon-restore_page: "\ec7b";
$icon-speaker_notes_off: "\ec7c";
$icon-delete_forever: "\ec7d";
$icon-accessibility_new: "\ec7e";
$icon-check_circle_outline: "\ec7f";
$icon-delete_outline: "\ec80";
$icon-done_outline: "\ec81";
$icon-maximize: "\ec82";
$icon-minimize: "\ec83";
$icon-offline_bolt: "\ec84";
$icon-swap_horizontal_circle: "\ec85";
$icon-accessible_forward: "\ec86";
$icon-calendar_today: "\ec87";
$icon-calendar_view_day: "\ec88";
$icon-label_important: "\ec89";
$icon-restore_from_trash: "\ec8a";
$icon-supervised_user_circle: "\ec8b";
$icon-text_rotate_up: "\ec8c";
$icon-text_rotate_vertical: "\ec8d";
$icon-text_rotation_angledown: "\ec8e";
$icon-text_rotation_angleup: "\ec8f";
$icon-text_rotation_down: "\ec90";
$icon-text_rotation_none: "\ec91";
$icon-commute: "\ec92";
$icon-arrow_right_alt: "\ec93";
$icon-work_off: "\ec94";
$icon-work_outline: "\ec95";
$icon-drag_indicator: "\ec96";
$icon-horizontal_split: "\ec97";
$icon-label_important_outline: "\ec98";
$icon-vertical_split: "\ec99";
$icon-voice_over_off: "\ec9a";
$icon-segment: "\ec9b";
$icon-contact_support: "\ec9c";
$icon-compress: "\ec9d";
$icon-filter_list_alt: "\ec9e";
$icon-expand: "\ec9f";
$icon-edit_off: "\eca0";
$icon-10k: "\eca1";
$icon-10mp: "\eca2";
$icon-11mp: "\eca3";
$icon-12mp: "\eca4";
$icon-13mp: "\eca5";
$icon-14mp: "\eca6";
$icon-15mp: "\eca7";
$icon-16mp: "\eca8";
$icon-17mp: "\eca9";
$icon-18mp: "\ecaa";
$icon-19mp: "\ecab";
$icon-1k: "\ecac";
$icon-1k_plus: "\ecad";
$icon-20mp: "\ecae";
$icon-21mp: "\ecaf";
$icon-22mp: "\ecb0";
$icon-23mp: "\ecb1";
$icon-24mp: "\ecb2";
$icon-2k: "\ecb3";
$icon-2k_plus: "\ecb4";
$icon-2mp: "\ecb5";
$icon-3k: "\ecb6";
$icon-3k_plus: "\ecb7";
$icon-3mp: "\ecb8";
$icon-4k_plus: "\ecb9";
$icon-4mp: "\ecba";
$icon-5k: "\ecbb";
$icon-5k_plus: "\ecbc";
$icon-5mp: "\ecbd";
$icon-6k: "\ecbe";
$icon-6k_plus: "\ecbf";
$icon-6mp: "\ecc0";
$icon-7k: "\ecc1";
$icon-7k_plus: "\ecc2";
$icon-7mp: "\ecc3";
$icon-8k: "\ecc4";
$icon-8k_plus: "\ecc5";
$icon-8mp: "\ecc6";
$icon-9k: "\ecc7";
$icon-9k_plus: "\ecc8";
$icon-9mp: "\ecc9";
$icon-account_tree: "\ecca";
$icon-add_chart: "\eccb";
$icon-add_ic_call: "\eccc";
$icon-add_moderator: "\eccd";
$icon-all_inbox: "\ecce";
$icon-approval: "\eccf";
$icon-assistant_direction: "\ecd0";
$icon-assistant_navigation: "\ecd1";
$icon-bookmarks: "\ecd2";
$icon-bus_alert: "\ecd3";
$icon-cases: "\ecd4";
$icon-circle_notifications: "\ecd5";
$icon-closed_caption_off: "\ecd6";
$icon-connected_tv: "\ecd7";
$icon-dangerous: "\ecd8";
$icon-dashboard_customize: "\ecd9";
$icon-desktop_access_disabled: "\ecda";
$icon-drive_file_move_outline: "\ecdb";
$icon-drive_file_rename_outline: "\ecdc";
$icon-drive_folder_upload: "\ecdd";
$icon-duo: "\ecde";
$icon-explore_off: "\ecdf";
$icon-file_download_done: "\ece0";
$icon-rtt: "\ece1";
$icon-grid_view: "\ece2";
$icon-hail: "\ece3";
$icon-home_filled: "\ece4";
$icon-imagesearch_roller: "\ece5";
$icon-label_off: "\ece6";
$icon-library_add_check: "\ece7";
$icon-logout: "\ece8";
$icon-margin: "\ece9";
$icon-mark_as_unread: "\ecea";
$icon-menu_open: "\eceb";
$icon-mp: "\ecec";
$icon-offline_share: "\eced";
$icon-padding: "\ecee";
$icon-panorama_photosphere: "\ecef";
$icon-panorama_photosphere_select: "\ecf0";
$icon-person_add_disabled: "\ecf1";
$icon-phone_disabled: "\ecf2";
$icon-phone_enabled: "\ecf3";
$icon-pivot_table_chart: "\ecf4";
$icon-print_disabled: "\ecf5";
$icon-railway_alert: "\ecf6";
$icon-recommend: "\ecf7";
$icon-remove_done: "\ecf8";
$icon-remove_moderator: "\ecf9";
$icon-repeat_on: "\ecfa";
$icon-repeat_one_on: "\ecfb";
$icon-replay_circle_filled: "\ecfc";
$icon-reset_tv: "\ecfd";
$icon-sd: "\ecfe";
$icon-shield: "\ecff";
$icon-shuffle_on: "\ed00";
$icon-speed: "\ed01";
$icon-stacked_bar_chart: "\ed02";
$icon-stream: "\ed03";
$icon-swipe: "\ed04";
$icon-switch_account: "\ed05";
$icon-tag: "\ed06";
$icon-thumb_down_off_alt: "\ed07";
$icon-thumb_up_off_alt: "\ed08";
$icon-toggle_off: "\ed09";
$icon-toggle_on: "\ed0a";
$icon-two_wheeler: "\ed0b";
$icon-upload_file: "\ed0c";
$icon-view_in_ar: "\ed0d";
$icon-waterfall_chart: "\ed0e";
$icon-wb_shade: "\ed0f";
$icon-wb_twighlight: "\ed10";
$icon-home_work: "\ed11";
$icon-schedule_send: "\ed12";
$icon-bolt: "\ed13";
$icon-send_and_archive: "\ed14";
$icon-workspaces_filled: "\ed15";
$icon-file_present: "\ed16";
$icon-workspaces_outline: "\ed17";
$icon-fit_screen: "\ed18";
$icon-saved_search: "\ed19";
$icon-storefront: "\ed1a";
$icon-amp_stories: "\ed1b";
$icon-dynamic_feed: "\ed1c";
$icon-euro: "\ed1d";
$icon-height: "\ed1e";
$icon-policy: "\ed1f";
$icon-sync_alt: "\ed20";
$icon-menu_book: "\ed21";
$icon-emoji_flags: "\ed22";
$icon-emoji_food_beverage: "\ed23";
$icon-emoji_nature: "\ed24";
$icon-emoji_people: "\ed25";
$icon-emoji_symbols: "\ed26";
$icon-emoji_transportation: "\ed27";
$icon-post_add: "\ed28";
$icon-people_alt: "\ed29";
$icon-emoji_emotions: "\ed2a";
$icon-emoji_events: "\ed2b";
$icon-emoji_objects: "\ed2c";
$icon-sports_basketball: "\ed2d";
$icon-sports_cricket: "\ed2e";
$icon-sports_esports: "\ed2f";
$icon-sports_football: "\ed30";
$icon-sports_golf: "\ed31";
$icon-sports_hockey: "\ed32";
$icon-sports_mma: "\ed33";
$icon-sports_motorsports: "\ed34";
$icon-sports_rugby: "\ed35";
$icon-sports_soccer: "\ed36";
$icon-sports: "\ed37";
$icon-sports_volleyball: "\ed38";
$icon-sports_tennis: "\ed39";
$icon-sports_handball: "\ed3a";
$icon-sports_kabaddi: "\ed3b";
$icon-eco: "\ed3c";
$icon-museum: "\ed3d";
$icon-flip_camera_android: "\ed3e";
$icon-flip_camera_ios: "\ed3f";
$icon-cancel_schedule_send: "\ed40";
$icon-apartment: "\ed41";
$icon-bathtub: "\ed42";
$icon-deck: "\ed43";
$icon-fireplace: "\ed44";
$icon-house: "\ed45";
$icon-king_bed: "\ed46";
$icon-nights_stay: "\ed47";
$icon-outdoor_grill: "\ed48";
$icon-single_bed: "\ed49";
$icon-square_foot: "\ed4a";
$icon-double_arrow: "\ed4b";
$icon-sports_baseball: "\ed4c";
$icon-attractions: "\ed4d";
$icon-bakery_dining: "\ed4e";
$icon-breakfast_dining: "\ed4f";
$icon-car_rental: "\ed50";
$icon-car_repair: "\ed51";
$icon-dinner_dining: "\ed52";
$icon-dry_cleaning: "\ed53";
$icon-hardware: "\ed54";
$icon-liquor: "\ed55";
$icon-lunch_dining: "\ed56";
$icon-nightlife: "\ed57";
$icon-park: "\ed58";
$icon-ramen_dining: "\ed59";
$icon-celebration: "\ed5a";
$icon-theater_comedy: "\ed5b";
$icon-badge: "\ed5c";
$icon-festival: "\ed5d";
$icon-icecream: "\ed5e";
$icon-volunteer_activism: "\ed5f";
$icon-contactless: "\ed60";
$icon-delivery_dining: "\ed61";
$icon-brunch_dining: "\ed62";
$icon-takeout_dining: "\ed63";
$icon-ac_unit: "\ed64";
$icon-airport_shuttle: "\ed65";
$icon-all_inclusive: "\ed66";
$icon-beach_access: "\ed67";
$icon-business_center: "\ed68";
$icon-casino: "\ed69";
$icon-child_care: "\ed6a";
$icon-child_friendly: "\ed6b";
$icon-fitness_center: "\ed6c";
$icon-golf_course: "\ed6d";
$icon-hot_tub: "\ed6e";
$icon-kitchen: "\ed6f";
$icon-pool: "\ed70";
$icon-room_service: "\ed71";
$icon-smoke_free: "\ed72";
$icon-smoking_rooms: "\ed73";
$icon-spa: "\ed74";
$icon-no_meeting_room: "\ed75";
$icon-meeting_room: "\ed76";
$icon-goat: "\ed77";
$icon-5g: "\ed78";
$icon-ad_units: "\ed79";
$icon-add_business: "\ed7a";
$icon-add_location_alt: "\ed7b";
$icon-add_road: "\ed7c";
$icon-add_to_drive: "\ed7d";
$icon-addchart: "\ed7e";
$icon-admin_panel_settings: "\ed7f";
$icon-agriculture: "\ed80";
$icon-alt_route: "\ed81";
$icon-analytics: "\ed82";
$icon-anchor: "\ed83";
$icon-animation: "\ed84";
$icon-api: "\ed85";
$icon-app_blocking: "\ed86";
$icon-app_registration: "\ed87";
$icon-app_settings_alt: "\ed88";
$icon-architecture: "\ed89";
$icon-arrow_circle_down: "\ed8a";
$icon-arrow_circle_up: "\ed8b";
$icon-article: "\ed8c";
$icon-attach_email: "\ed8d";
$icon-auto_awesome: "\ed8e";
$icon-auto_awesome_mosaic: "\ed8f";
$icon-auto_awesome_motion: "\ed90";
$icon-auto_delete: "\ed91";
$icon-auto_fix_high: "\ed92";
$icon-auto_fix_normal: "\ed93";
$icon-auto_fix_off: "\ed94";
$icon-auto_stories: "\ed95";
$icon-baby_changing_station: "\ed96";
$icon-backpack: "\ed97";
$icon-backup_table: "\ed98";
$icon-batch_prediction: "\ed99";
$icon-bedtime: "\ed9a";
$icon-bento: "\ed9b";
$icon-bike_scooter: "\ed9c";
$icon-biotech: "\ed9d";
$icon-block_flipped: "\ed9e";
$icon-browser_not_supported: "\ed9f";
$icon-build_circle: "\eda0";
$icon-calculate: "\eda1";
$icon-campaign: "\eda2";
$icon-carpenter: "\eda3";
$icon-cast_for_education: "\eda4";
$icon-charging_station: "\eda5";
$icon-checkroom: "\eda6";
$icon-circle: "\eda7";
$icon-cleaning_services: "\eda8";
$icon-close_fullscreen: "\eda9";
$icon-closed_caption_disabled: "\edaa";
$icon-comment_bank: "\edab";
$icon-construction: "\edac";
$icon-corporate_fare: "\edad";
$icon-countertops: "\edae";
$icon-design_services: "\edaf";
$icon-directions_off: "\edb0";
$icon-dirty_lens: "\edb1";
$icon-do_not_step: "\edb2";
$icon-do_not_touch: "\edb3";
$icon-domain_verification: "\edb4";
$icon-drive_file_move: "\edb5";
$icon-dry: "\edb6";
$icon-dynamic_form: "\edb7";
$icon-east: "\edb8";
$icon-edit_road: "\edb9";
$icon-electric_bike: "\edba";
$icon-electric_car: "\edbb";
$icon-electric_moped: "\edbc";
$icon-electric_rickshaw: "\edbd";
$icon-electric_scooter: "\edbe";
$icon-electrical_services: "\edbf";
$icon-elevator: "\edc0";
$icon-engineering: "\edc1";
$icon-escalator: "\edc2";
$icon-escalator_warning: "\edc3";
$icon-face_retouching_natural: "\edc4";
$icon-fact_check: "\edc5";
$icon-family_restroom: "\edc6";
$icon-fence: "\edc7";
$icon-filter_alt: "\edc8";
$icon-fire_extinguisher: "\edc9";
$icon-flaky: "\edca";
$icon-food_bank: "\edcb";
$icon-forward_to_inbox: "\edcc";
$icon-foundation: "\edcd";
$icon-grading: "\edce";
$icon-grass: "\edcf";
$icon-handyman: "\edd0";
$icon-hdr_enhanced_select: "\edd1";
$icon-hearing_disabled: "\edd2";
$icon-help_center: "\edd3";
$icon-highlight_alt: "\edd4";
$icon-history_edu: "\edd5";
$icon-history_toggle_off: "\edd6";
$icon-home_repair_service: "\edd7";
$icon-horizontal_rule: "\edd8";
$icon-hourglass_bottom: "\edd9";
$icon-hourglass_disabled: "\edda";
$icon-hourglass_top: "\eddb";
$icon-house_siding: "\eddc";
$icon-hvac: "\eddd";
$icon-image_not_supported: "\edde";
$icon-insights: "\eddf";
$icon-integration_instructions: "\ede0";
$icon-ios_share: "\ede1";
$icon-legend_toggle: "\ede2";
$icon-local_fire_department: "\ede3";
$icon-local_police: "\ede4";
$icon-location_pin: "\ede5";
$icon-lock_clock: "\ede6";
$icon-login: "\ede7";
$icon-maps_ugc: "\ede8";
$icon-mark_chat_read: "\ede9";
$icon-mark_chat_unread: "\edea";
$icon-mark_email_read: "\edeb";
$icon-mark_email_unread: "\edec";
$icon-mediation: "\eded";
$icon-medical_services: "\edee";
$icon-mic_external_off: "\edef";
$icon-mic_external_on: "\edf0";
$icon-microwave: "\edf1";
$icon-military_tech: "\edf2";
$icon-miscellaneous_services: "\edf3";
$icon-model_training: "\edf4";
$icon-monitor: "\edf5";
$icon-moped: "\edf6";
$icon-more_time: "\edf7";
$icon-motion_photos_off: "\edf8";
$icon-motion_photos_on: "\edf9";
$icon-motion_photos_paused: "\edfa";
$icon-multiple_stop: "\edfb";
$icon-nat: "\edfc";
$icon-near_me_disabled: "\edfd";
$icon-next_plan: "\edfe";
$icon-night_shelter: "\edff";
$icon-nightlight_round: "\ee00";
$icon-no_cell: "\ee01";
$icon-no_drinks: "\ee02";
$icon-no_flash: "\ee03";
$icon-no_food: "\ee04";
$icon-no_meals: "\ee05";
$icon-no_photography: "\ee06";
$icon-no_stroller: "\ee07";
$icon-no_transfer: "\ee08";
$icon-north: "\ee09";
$icon-north_east: "\ee0a";
$icon-north_west: "\ee0b";
$icon-not_accessible: "\ee0c";
$icon-not_started: "\ee0d";
$icon-online_prediction: "\ee0e";
$icon-open_in_full: "\ee0f";
$icon-outbox: "\ee10";
$icon-outgoing_mail: "\ee11";
$icon-outlet: "\ee12";
$icon-panorama_horizontal_select: "\ee13";
$icon-panorama_vertical_select: "\ee14";
$icon-panorama_wide_angle_select: "\ee15";
$icon-payments: "\ee16";
$icon-pedal_bike: "\ee17";
$icon-pending: "\ee18";
$icon-pending_actions: "\ee19";
$icon-person_add_alt: "\ee1a";
$icon-person_add_alt_1: "\ee1b";
$icon-person_remove: "\ee1c";
$icon-person_search: "\ee1d";
$icon-pest_control: "\ee1e";
$icon-pest_control_rodent: "\ee1f";
$icon-photo_camera_back: "\ee20";
$icon-photo_camera_front: "\ee21";
$icon-plagiarism: "\ee22";
$icon-play_disabled: "\ee23";
$icon-plumbing: "\ee24";
$icon-point_of_sale: "\ee25";
$icon-preview: "\ee26";
$icon-privacy_tip: "\ee27";
$icon-psychology: "\ee28";
$icon-public_off: "\ee29";
$icon-push_pin: "\ee2a";
$icon-qr_code: "\ee2b";
$icon-qr_code_scanner: "\ee2c";
$icon-quickreply: "\ee2d";
$icon-read_more: "\ee2e";
$icon-receipt_long: "\ee2f";
$icon-request_quote: "\ee30";
$icon-rice_bowl: "\ee31";
$icon-roofing: "\ee32";
$icon-room_preferences: "\ee33";
$icon-rule: "\ee34";
$icon-rule_folder: "\ee35";
$icon-run_circle: "\ee36";
$icon-science: "\ee37";
$icon-screen_search_desktop: "\ee38";
$icon-search_off: "\ee39";
$icon-self_improvement: "\ee3a";
$icon-sensor_door: "\ee3b";
$icon-sensor_window: "\ee3c";
$icon-set_meal: "\ee3d";
$icon-shopping_bag: "\ee3e";
$icon-signal_cellular_0_bar: "\ee3f";
$icon-signal_wifi_0_bar: "\ee40";
$icon-smart_button: "\ee41";
$icon-snippet_folder: "\ee42";
$icon-soap: "\ee43";
$icon-source: "\ee44";
$icon-south: "\ee45";
$icon-south_east: "\ee46";
$icon-south_west: "\ee47";
$icon-sports_bar: "\ee48";
$icon-stairs: "\ee49";
$icon-star_outline1: "\ee4a";
$icon-star_rate: "\ee4b";
$icon-sticky_note_2: "\ee4c";
$icon-stop_circle: "\ee4d";
$icon-stroller: "\ee4e";
$icon-subscript: "\ee4f";
$icon-subtitles_off: "\ee50";
$icon-superscript: "\ee51";
$icon-support: "\ee52";
$icon-support_agent: "\ee53";
$icon-switch_left: "\ee54";
$icon-switch_right: "\ee55";
$icon-table_rows: "\ee56";
$icon-table_view: "\ee57";
$icon-tapas: "\ee58";
$icon-taxi_alert: "\ee59";
$icon-text_snippet: "\ee5a";
$icon-tour: "\ee5b";
$icon-tty: "\ee5c";
$icon-umbrella: "\ee5d";
$icon-upgrade: "\ee5e";
$icon-verified: "\ee5f";
$icon-video_settings: "\ee60";
$icon-view_sidebar: "\ee61";
$icon-wash: "\ee62";
$icon-water_damage: "\ee63";
$icon-west: "\ee64";
$icon-wheelchair_pickup: "\ee65";
$icon-wifi_calling: "\ee66";
$icon-wifi_protected_setup: "\ee67";
$icon-wine_bar: "\ee68";
$icon-wrong_location: "\ee69";
$icon-wysiwyg: "\ee6a";
$icon-leaderboard: "\ee6b";
$icon-6_ft_apart: "\ee6c";
$icon-book_online: "\ee6d";
$icon-clean_hands: "\ee6e";
$icon-connect_without_contact: "\ee6f";
$icon-coronavirus: "\ee70";
$icon-elderly: "\ee71";
$icon-follow_the_signs: "\ee72";
$icon-leave_bags_at_home: "\ee73";
$icon-masks: "\ee74";
$icon-reduce_capacity: "\ee75";
$icon-sanitizer: "\ee76";
$icon-send_to_mobile: "\ee77";
$icon-sick: "\ee78";
$icon-add_task: "\ee79";
$icon-contact_page: "\ee7a";
$icon-disabled_by_default: "\ee7b";
$icon-facebook: "\ee7c";
$icon-groups: "\ee7d";
$icon-luggage: "\ee7e";
$icon-no_backpack: "\ee7f";
$icon-no_luggage: "\ee80";
$icon-outbond: "\ee81";
$icon-published_with_changes: "\ee82";
$icon-request_page: "\ee83";
$icon-stacked_line_chart: "\ee84";
$icon-unpublished: "\ee85";
$icon-align_horizontal_center: "\ee86";
$icon-align_horizontal_left: "\ee87";
$icon-align_horizontal_right: "\ee88";
$icon-align_vertical_bottom: "\ee89";
$icon-align_vertical_center: "\ee8a";
$icon-align_vertical_top: "\ee8b";
$icon-horizontal_distribute: "\ee8c";
$icon-qr_code_2: "\ee8d";
$icon-update_disabled: "\ee8e";
$icon-vertical_distribute: "\ee8f";

