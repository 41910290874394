@import "../../../styles/mixins.scss";
@import "../../../styles/variables";

.container-wrapper {
  .hero-image {
    // background-image: url('https://www.larm-signalteknik.se/pictures/1920-adobestock_226029988.jpg?v20190918144522');
    overflow: hidden;
    // background-size: cover;
    height: 100vh;

    video {
      @include breakpoint($md) {
        width: 117%;
        height: auto;
      }
    }
  }

  .dark-filter {
    background: #000000;
    opacity: 0.2;
    height: 100vh;
    width: 100vw;
  }

  .faded-logo {
    position: absolute;
    width: 850px;
    opacity: 0.1;
  }

  .icon-scroll,
  .icon-scroll:before {
    position: absolute;
    left: 50%;
  }
  .icon-scroll {
    width: 40px;
    height: 70px;
    margin-left: -20px;
    bottom: 10%;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 25px;
  }
  .icon-scroll:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
  }

  .icon-scroll:hover {
    cursor: pointer;
  }
  @-webkit-keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(46px);
    }
  }
  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(46px);
    }
  }

  .usp-container {
    .border-icon-circle {
      margin-right: 50px;
      box-shadow: 6px 6px 20px 0px #dfdfdf,
        -6px -6px 20px 0px rgba(209, 204, 204, 0.3);
      border-radius: 50px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
    }
  }
  .section {
    width: 100%;
    text-align: left;

    .section-container {
      margin: 0 auto;
      padding: 30px;
      max-width: 1100px;
    }

    .section-container-contact {
      padding: 30px;
      width: 70vw;
    }
  }
}
