@import "./mixins";
@import "./variables";

// font

h1 {
  font-size: 40px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
  font-weight: bold;
}

.u-fs-16 {
  font-size: 16px;
}

.u-fs-uppercase {
  text-transform: uppercase;
}

// col

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.md-col-4 {
  @include breakpoint($md) {
    flex: 0 0 33.333333% !important;
    max-width: 33.333333% !important;
  }
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.md-col-6 {
  @include breakpoint($md) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.md-col-9 {
  @include breakpoint($md) {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.xs-col-12 {
  @include breakpoint($xs) {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

// position

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

// flex

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.justify-content-start {
  justify-content: start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}

// padding
.xs-pl-0 {
  @include breakpoint($xs) {
    padding-left: 0px;
  }
}
.xs-pr-0 {
  @include breakpoint($xs) {
    padding-right: 0px;
  }
}
.lg-pl-8 {
  @include breakpoint($xs) {
    padding-left: 8px
  }
}
.lg-pr-8 {
  @include breakpoint($xs) {
    padding-right: 8px
  }
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pr-8 {
  padding-right: 8px;
}
.pl-8 {
  padding-left: 8px;
}

.pb-0 {
  padding-bottom: 0px !important;
}
.pb-8 {
  padding-bottom: 8px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-24 {
  padding-top: 24px;
}
.pt-50 {
  padding-top: 50px;
}

.pt-100 {
  padding-top: 100px;
}

// margin

.m-auto {
  margin: 0 auto;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-16 {
  margin-bottom: 16px;
}
.mb-50 {
  margin-bottom: 50px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

// height

.h-100 {
  height: 100%;
}

.h-60 {
  height: 60vh;
}

// z-index

.z-i-1 {
  z-index: 1;
}
.z-i-2 {
  z-index: 2;
}
.z-i-3 {
  z-index: 3;
}
.z-i-4 {
  z-index: 4;
}

// Global
.container {
  width: 1120px;
  margin: 0 auto;
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-40 {
  font-size: 40px;
}

.fi {
  font-style: italic;
}
