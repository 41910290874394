.services-hero {
  height: 40vh;

  .dark-filter {
    background: #000000;
    opacity: 0.2;
    height: 40vh;
    width: 100vw;
  }

  .faded-logo {
    position: absolute;
    width: 300px;
    opacity: 0.2;
  }
}

.section {
  width: 100%;
  text-align: left;

  .section-container {
    margin: 0 auto;
    padding: 30px;
    max-width: 1100px;
  }

  .section-container-contact {
    padding: 30px;
    width: 70vw;
  }
}

.activeSection {
  animation: fadeInLeft 500ms ease-out;
  // position: absolute;
  top: 0;
}

@keyframes fadeInLeft {
  from {
    left: 30px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}
